/**
 * 获取当前系统所处环境
 * 本地开发环境 测试环境 生产环境
 */
export default function getEnv() {
  const url = location.host
  let env = 'prod'
  if (/teacher.dingdingkaike.com/.test(url)) {
    env = 'prod'
  } else if (/teacher-release.dingdingkaike.com/.test(url)) {
    env = 'release'
  } else if (/teacher-test.dingdingkaike.com/.test(url)) {
    env = 'test'
  } else {
    env = 'localhost'
  }
  return env
}

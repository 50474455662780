<!--
 * @Author: cyy
 * @Date: 2021-12-17 17:45:21
 * @LastEditTime: 2022-01-26 10:39:08
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\components\base\pagination.vue
-->
<template>
  <div class="pagination" v-if="total > limit">
    <el-pagination
      :pager-count="pagercount"
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page="currPage"
      :page-sizes="[15, 30, 45, 60]"
      :page-size="limit"
      :layout="
        isNew ? 'total, sizes, prev, pager, next, jumper' : 'prev, pager, next'
      "
      :total="total"
    ></el-pagination>
  </div>
  <!-- v-if="total > limit" -->
</template>
<script>
export default {
  name: 'pagination',

  props: {
    //页码按钮
    pagercount: {
      type: Number,
      default: 3,
    },
    // 总条数
    total: Number,
    isNew: {
      type: Boolean,
      default: true,
    },
    currPage: Number,
    limit: {
      type: Number,
      default: 15,
    },
  },

  methods: {
    sizeChange(value) {
      this.$emit('sizeChange', value)
    },

    currentChange(value) {
      this.$emit('currentChange', value)
    },
  },
}
</script>
<style lang="scss" scoped>
.pagination {
  padding: 50px 0 30px;
  text-align: center;
}
::v-deep .el-pager li {
  background: #f4f4f5;
  margin: 0 4px;
  padding: 0;
  font-size: 12px;
}
::v-deep .el-pagination button {
  background: #f4f4f5 !important;
}
</style>

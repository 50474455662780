/**
 *  @description 老师页面
 */

const router = {
  path: '/manageTeacher',
  name: 'manageTeacher',
  component: () => import('@view/teachingAdmin/staff'),
  meta: { requiresAuth: true, menu_id: 151 },
  children: [
    {
      path: 'updataPeople',
      name: 'updataPeople',
      component: () => import('@view/teachingAdmin/updataPeople'),
      meta: { pageOption: { name: '编辑老师' } }
    },
    {
      path: 'addTeacher',
      name: 'addTeacher',
      component: () => import('@view/teachingAdmin/addTeacher'),
      meta: { pageOption: { name: '添加员工' } }
    },
  ]
}

export default router

/*
 * @Author: cyy
 * @version: 
 * @Date: 2022-06-27 15:38:45
 * @LastEditTime: 2022-11-04 14:49:12
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @Description: 
 * @FilePath: \teacherdingding.com\src\router\module\LiveBroadcastManagement\messageTemplate.js
 */

/**
 *  @description 话术本
 */

export const router = {
    path: '/messageTemplate',
    name: 'messageTemplate',
    component: () => import('@view/LiveBroadcastManagement/messageTemplate/main'),
    meta: { requiresAuth: true, menu_id: 209 },

}

export const router2 = {
    path: '/messageTemplate/messageDetail',
    name: 'messageDetail',
    component: () =>
        import('@view/LiveBroadcastManagement/messageTemplate/messageDetail'),
    meta: {  requiresAuth: true, pageOption: { name: '详情' } },
}




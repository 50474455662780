/*
 * @Author: cyy
 * @version: 
 * @Date: 2021-03-06 00:26:18
 * @LastEditTime: 2022-05-09 14:13:12
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacher.dingdingkaike\src\router\module\schollAdmin\ComputerScholl.js
 */
/**
 *  @description 电脑网校页面
 */

const router = {
  path: '/pcSchool',
  name: 'pcSchool',
  component: () => import('@view/NetSchollAdmin/ComputerScholl/ComputerScholl'),
  meta: { requiresAuth: true, menu_id: 3 }
}

export default router

/*
 * @Descripttion:公开课
 * @version: 1.4.2.3
 * @Author: cyy
 * @Date: 2021-04-19 17:29:22
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-10-18 16:00:32
 */
/**
 *  @description 公开课视频连麦
 */

 const router = {
    path: '/videoCall/:id',
    component: () => import('@view/videoCall/main'),
    meta: { requiresAuth: true, topPage: true },
}

export default router

/*
 * @Author: cyy
 * @version: 1.4.6.4
 * @Date: 2022-03-29 11:25:20
 * @LastEditTime: 2022-11-21 14:25:01
 * @LastEditors: cyy
 * @Description: 推广员
 * @FilePath: \teacherdingding.com\src\router\module\marketingCenter\promoter.js
 */

const router = {
    path: '/promoter',
    name: 'promoter',
    component: () => import('@view/MarketingCenter/promoter/main'),
    meta: { requiresAuth: true, menu_id: 210 },
    children: [
        {
            path: 'promoterDetail',
            name: 'promoterDetail',
            component: () => import('@view/MarketingCenter/promoter/promoterDetail'),
            meta: { pageOption: { name: '详情' } }
        },
        {
            path: 'batchImport',
            name: 'batchImport',
            component: () => import('@view/MarketingCenter/promoter/batchImport'),
            meta: { pageOption: { name: '批量导入' } }
        }, {
            path: 'promoteManagement',
            name: 'promoteManagement',
            component: () => import('@view/MarketingCenter/promoter/promoteManagement'),
            meta: { pageOption: { name: '推广商品管理' } }
        },

    ]

}

export default router
import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/request/axios'
import QWebChannel from '@ass/js/QWebChannel'
import tool from '@ass/js/tool'
import netSchoolDecration from './modules/netSchoolDecration'
import pcSchoolDecration from './modules/pcSchoolDecration'
import OpenClassopenClassLive from './modules/OpenClassopenClassLive'
import { logOut } from '@ass/js/cookie'
import courseSchedule from './modules/courseSchedule'
import * as Sentry from '@sentry/vue'

Vue.use(Vuex)

let myStore

export default myStore = new Vuex.Store({
  modules: {
    netSchoolDecration,
    pcSchoolDecration,
    OpenClassopenClassLive,
    courseSchedule,
  },
  state: {
    // 用户权限
    roots: [],

    //获取本地域名

    // 轮播图更换图片弹窗控制
    changgePicturestatus: false,
    //课程分类
    kechengtypestatus: false,
    //自定网校内容
    selectChangestatus: false,
    //图标导航
    selecticonstatus: false,

    // 用户信息
    userInfo: {
      isLogin: false,
      teacher: {},
      remainingTime: [],
    },

    amount: 0,

    pageSize: [15, 30, 45, 60],

    // 定制化机构信息
    customJgInfo: {
      // 用户协议
      register_content: '',
      // 客服二维码
      serviceUrl: '',
      adminPageLogo: '',
      footerText: '',
      isCustome: false,
    },

    originUserInfo: {},

    // 当前所在大的模块
    currRouterPage: {
      menu_id: '',
      name: '',
    },

    // 左侧菜单列表
    menuList: [],

    // 与客户端交互
    QWebChannel: window.qt
      ? new QWebChannel(window.qt.webChannelTransport, function(data) {
          myStore.commit('windowsAppV2Change', !!data.objects.ddkkApp)
        })
      : undefined,

    // 是否是客户端
    isWindowsApp: !!window.qt,

    // 是否是客户端V2版本
    isWindowsAppV2: false,

    // 错误提示
    errorMessage: '',

    // 首页默认展示广告弹窗
    advertising: {
      src: '',
      url: '',
    },

    // 地址栏jid
    jid: tool.urlParams().jid || '',

    // 是否开启obs推流
    _obsPushStream: false,
  },

  getters: {
    // 是否可以obs推流
    obsPushStream() {
      return true
    },

    // 是否可选极速直播模式
    isSpeedLive: () => true,

    // 是否是免费版本
    isVersionFree(state) {
      return Number(state.userInfo.version_id) === 1
    },

    // 是否是标准版本
    isVersionStandard(state) {
      return Number(state.userInfo.version_id) === 2
    },

    // 是否是管理员
    isJiGouAdmin(state) {
      return Number(state.userInfo.is_jigouadmin) === 1
    },

    // 是否开通PC网校
    isOpenpcHome(state) {
      return Number(state.userInfo.pc_home) === 1
    },

    // 默认选中第一个有权限的页面
    homePath(state, { allMenuList }) {
      let path
      try {
        path = state.isWindowsAppV2
          ? '/class'
          : allMenuList.find(item => item.is_select == 1).path
      } catch (e) {
        console.error(e)
        path = '/class'
      }
      return path
    },

    // 新的左侧菜单列表
    // showCannotNav 是否需要升级弹窗
    menuList(state) {
      return state.menuList
        .filter(item =>
          item.menu && Array.isArray(item.menu)
            ? item.menu.some(v => state.roots.includes(Number(v.menu_id)))
            : false
        )
        .map(item => {
          return _.assign({}, item, {
            // showCannotNav: isVersionFree,
            // 已废弃
            disable: true,
            // 权限显示
            menu:
              item.menu && Array.isArray(item.menu)
                ? item.menu.filter(v => state.roots.includes(Number(v.menu_id)))
                : [],
          })
        })
    },

    allMenuList(state, { menuList }) {
      // 获取all menu 的值
      const menu = []

      menuList.forEach(item => {
        if (item.menu && Array.isArray(item.menu)) {
          item.menu.forEach(item => {
            menu.push(item)
          })
        }
      })

      menu.push({
        menu_id: '59',
        name: '账号设置',
        path: '/AccountSettings',
      })

      return menu
    },
  },

  mutations: {
    /**
     * @desc 请求用户信息的请求promise 或者重置
     * @param state
     * @param request promise or null
     */
    //网校装修-轮播图-更换图片
    changgePicture(state, value) {
      state.changgePicturestatus = value
    },
    //课程分类
    kechengtype(state, value) {
      state.kechengtypestatus = value
    },
    //自定网校内容
    selectChange(state, value) {
      state.selectChangestatus = value
    },

    //图标导航-选择图标
    selecticon(state, value) {
      state.selecticonstatus = value
    },

    // 减少容量
    setLeftStorage(state, value) {
      state.userInfo.leftStorage = value
    },

    // 设置定制化信息
    jigouCustomInfo(state, value) {
      const newValue = value || {}
      state.customJgInfo = {
        serviceUrl: newValue.customer_qrcode || '',
        adminPageLogo: newValue.logo || '',
        footerText: newValue.pc_footer || '',
        register_content: newValue.register_content || '',
        isCustome: !!value,
      }
      state.jid = newValue.jg_id

      const favicon = document.querySelector('link[rel="icon"]')
      if (value) {
        favicon.href = newValue.favicon || ''
      } else {
        favicon.href = 'https://teacher.dingdingkaike.com/favicon.ico'
      }
    },

    getAmountdata(state, info) {
      state.amount = info
    },

    // 不要了 但是其他地方调用了 勿删
    setUserInfoRequest() {},

    setUserInfo(state, info) {
      if (info) {
        state.originUserInfo = info
        // 全局分页选项
        state.pageSize = info.pageSize
        // 广告弹窗
        state.advertising = info.advertising || {}
        // obs 推流 1 开启 2 关闭
        state._obsPushStream = info.obsPushStream == 1
        //网校概况头信息

        // 权限
        try {
          state.roots = info.menu_ids
            ? info.menu_ids.split(',').map(item => Number(item))
            : []
        } catch (e) {
          state.roots = []
        }

        state.userInfo = Object.assign(
          {},
          state.userInfo,
          {
            // 是否开通推广员功能
            is_promoter: info.is_promoter,
            // pc端学生登录设置 ['1手机号+短信验证码','2手机号+密码','3微信登录],如果选择数组就有代号，否则没有如[1,3] [1,2]
            login_mode: info.login_mode,
            // 是否开通支付宝花呗分期功能 1 是 2 否
            is_alipay_flowers: info.is_alipay_flowers == 1,
            // 1正常  2停用  3清理了
            status_type: info.status_type,
            //true 最近24小时内注册   false 24小时外注册的
            is_new_jigou: info.is_new_jigou,
            //是否开通作业本功能 1开通  2 未开通
            is_workbook: info.is_workbook,
            // 1开通  2没开通 会员
            is_course_member: info.is_course_member,
            //收费模式 1按量收费 2不按量收费 默认1
            charge_mode: info.charge_mode,
            //判断登录的用户是否被移除网校 1 被移除    2正常
            is_remove: info.is_remove,
            //判断是否已有网校 2是有 1是没有
            newschool: info.newschool,
            is_jigouadmin: 1,
            // 当前用户id
            uid: info.uid,
            // 当前机构id
            jg_id: info.jg_id,
            //是否开启pc客户端
            jg_is_pc_client: info.is_pc_client,
            // 当前机构名称
            jg_title: info.title,
            // 当前机构logo
            jg_logo: info.jg_logo,
            // 当前机构l是否开启保护 1开2关
            jg_is_drm: info.is_drm,
            // 分页数量
            limit: info.limit,
            // 用户手机
            umobile: info.umobile,
            // 用户名称
            uname: info.uname,
            // 是否可选极速直播
            is_speed_live: Number(info.is_speed_live),
            // 用户头像
            uphoto: info.uphoto,
            // 是否开启了PC网校
            pc_home: Number(info.pc_home),
            // 版本名称 免费 标准 专业 尊享
            versionName: info.name,
            // PC网校地址
            pcHomeDomain: info.domain,
            // 版本类型
            version_id: Number(info.version_id),
            // 当前版本 机构老师最大数量
            teacherMaxNum: info.teacher_num,
            // web手机管理后台地址
            teacherDomain: info.teacherDomain,
            // 版本到期时间 开始 结束时间
            remainingTime: info.remainingTime || [0, 0],
            // 聊天室账号
            chatRoom_creator: info.chatRoom_creator,
            // 聊天室密码
            chatRoom_token: info.chatRoom_token,
            // PC客户端下载地址
            pcClientDownload: info.pcClientDownload,
            // PC客户端版本号
            pcClientVersion: info.pcClientVersion,
            // obs客户端下载地址
            obsClientDownload: info.obsClientDownload,
            // 下载Windows客户端
            pc_client_address: info.pc_client_address,
            // 是否开通电子资料
            is_electronics: info.is_electronics,
            // 帮助中心地址
            helpPageUrl: 'https://www.yuque.com/dingdingkaike/doc/guide',
            // 叮豆
            t_amount: info.t_amount,
            jigouStorage: info.jigouStorage,
            leftStorage: info.leftStorage,
            lib: info.lib,
          },
          {
            isLogin: true,
          }
        )
      } else {
        state.userInfo = {
          isLogin: false,
          teacher: {},
          remainingTime: [],
        }
      }
    },

    setMenuList(state, info) {
      state.menuList = info
    },

    /**
     * @desc 通过当前页面路径 记录路由路径 如左侧页面菜单栏
     * @param state
     * @param path 路径
     */
    setRouterPage(state, { path, fullPath, allMenuList }) {
      // console.log(path, fullPath)
      // 地址相同 或者是 它的子路由
      state.currRouterPage =
        allMenuList.filter(
          item =>
            item.path === path ||
            item.path === fullPath ||
            (item.path &&
              /\/\w+/.test(item.path) &&
              new RegExp(`${item.path}/`, 'i').test(path))
        )[0] || {}
    },

    /**
     * @desc 获取某些接口（获取用户信息 和 给客户端发送消息）
     * 或者操作失败，需要弹窗提示，并且刷新
     * @param state
     * @param msg
     */
    setErrorMessage(state, msg = '页面出现异常，请刷新一下页面！') {
      state.errorMessage = msg
    },

    // obd 推流开关
    setObsPushStream(state, val) {
      state._obsPushStream = val
    },

    // 是否是客户端v2
    windowsAppV2Change(state, val) {
      state.isWindowsAppV2 = val
    },
  },

  actions: {
    /**
     * @desc 页面必须的一些用户信息 机构信息 所以必须等待
     * 这些基础信息获取完毕才进行下一步的操作，在路由拦截那边有做处理
     * 1. 数据正在获取中
     * 2. 数据未获取
     */

    getUserInfo({ commit, dispatch, state }, url) {
      try {
        return axios(
          {
            name: 'getUserInfo',
            isMultipleSelf: 'initialSelf',
            url: url || '/user/getUserInfo',
            data: {
              jg_id: state.jid,
            },
          },
          ({ data: { code, userInfo, menuInfo, jigouCustomInfo } }) => {
            // 定制化机构信息存在
            commit('jigouCustomInfo', jigouCustomInfo)
            if (!url) {
              // 后台返回重定向 那就退出
              if (code === 302) {
                dispatch('sendToWindowsMsg', { type: 4 })
                logOut()
              } else if (code === 200 && userInfo && userInfo.uid) {
                // 登陆后给客户端发送用户信息
                dispatch('afterLoginSendUserInfo', userInfo)
                // 成功的处理
                commit('setUserInfo', userInfo)
                commit('setMenuList', menuInfo)
                Sentry.setUser({
                  id: state.userInfo.uid,
                  username: state.userInfo.uname,
                })
              } else {
                commit('setErrorMessage')
              }
            }
          },
          () => {
            commit('setErrorMessage')
          }
        ).request
      } catch (e) {
        commit('setErrorMessage')
      }
    },

    /**
     * @description 对比新旧userInfo 是否相等
     */
    isEqualUserInfo({ state, commit, dispatch }) {
      axios(
        {
          name: 'isEqualUserInfo',
          url: '/user/getUserInfo',
          isMultipleSelf: 'replace',
          data: {
            jg_id: state.jid,
          },
        },
        ({ data: { userInfo, menuInfo, jigouCustomInfo } }) => {
          // 定制化机构信息存在
          commit('jigouCustomInfo', jigouCustomInfo)
          if (userInfo && !_.isEqual(state.originUserInfo, userInfo)) {
            // 登陆后给客户端发送用户信息
            dispatch('afterLoginSendUserInfo', userInfo)
            // 成功的处理
            commit('setUserInfo', userInfo)
            commit('setMenuList', menuInfo)
          }
        }
      )
    },

    /**
     *
     * @description 获取叮豆剩余数量
     *
     */

    getAmount({ commit }) {
      return new Promise(resvlove => {
        axios(
          {
            name: 'getAmount',
            url: 'user/getAmount',
          },
          ({ data: { code, data } }) => {
            if (code == 200) {
              commit('getAmountdata', data)
              resvlove()
            }
          }
        )
      })
    },

    getMenuList({ commit }) {
      try {
        const request = axios(
          {
            name: 'getMenu',
            url: '/menu/getMenu',
          },
          ({ data: { code, data } }) => {
            // 后台返回重定向 那就退出
            if (Number(code) === 302) {
              logOut()
            } else if (Number(code) === 200) {
              // 成功的处理
              commit('setMenuList', data)
            } else {
              commit('setErrorMessage')
            }
          },
          () => {
            commit('setErrorMessage')
          }
        ).request
        return request
      } catch (e) {
        commit('setErrorMessage')
      }
    },

    // 给客户端发送数据
    sendToWindowsMsg({ state }, option) {
      if (state.isWindowsApp) {
        try {
          if (state.isWindowsAppV2) {
            const QWebChannel = state.QWebChannel
            switch (Number(option.type)) {
              case 3:
                QWebChannel.objects.ddkkApp.openInBrowser(option.url)
                break
              case 5:
                QWebChannel.objects.loginWindow.setUserId(option.uid)
                break
              case -5:
                if (QWebChannel.objects.loginWindow.setJiGouId) {
                  QWebChannel.objects.loginWindow.setJiGouId(option.jg_id)
                }
                break
              case 0:
                // className: string, classTime: string, classId: int
                QWebChannel.objects.loginWindow.openLiveWindow(
                  option.course.title || option.course.name,
                  option.course.startTime,
                  option.course.correlation_id,
                  false
                )
                break
              case 6:
                // className: string, classTime: string, classId: int
                QWebChannel.objects.loginWindow.openLiveWindow(
                  option.course.name,
                  option.course.start_time,
                  option.course.open_class_id,
                  true
                )
                break
              case 7:
                QWebChannel.objects.loginWindow.openChatWindow(option.id)
                break
              case 10:
                QWebChannel.objects.deviceCheckWindow.accepted(option.state)
                break
              case 11:
                QWebChannel.objects.chatWindow.openVideoCallWindow()
                break
              case 12:
                QWebChannel.objects.videoCallWindow.openChatWindow()
                break
              case 13:
                QWebChannel.objects.videoCallWindow.close()
                break
              case 14:
                // console.log('发送PPT信息', QWebChannel.objects.documentWindow.documentApplied, option)
                QWebChannel.objects.documentWindow.documentApplied(
                  JSON.stringify(option)
                )
                if (option.callback) {
                  QWebChannel.objects.documentWindow.progressChanged.disconnect()
                  QWebChannel.objects.documentWindow.progressChanged.connect(
                    option.callback
                  )
                }
                break
              case 15:
                console.log('客户端监听函数 videoCallStateChange')
                QWebChannel.objects.videoCallWindow.videoCallStateChange.disconnect()
                QWebChannel.objects.videoCallWindow.videoCallStateChange.connect(
                  option.callback
                )
                break
              case 16:
                console.log('布局改变 changeLayout', option.value)
                QWebChannel.objects.videoCallWindow.changeLayout(option.value)
                break
              case 17:
                console.log('开关学生麦 muteStudent', option.value)
                QWebChannel.objects.videoCallWindow.muteStudent(option.value)
                break
              case 18:
                console.log('监听老师开关摄像头')
                QWebChannel.objects.videoCallWindow.teacherVideoMutabilityChanged.disconnect()
                QWebChannel.objects.videoCallWindow.teacherVideoMutabilityChanged.connect(
                  option.callback
                )
                break
              case 19:
                console.log('发送RTC房间数据 setRoomInfo')
                QWebChannel.objects.videoCallWindow.setRoomInfo(
                  JSON.stringify(option)
                )
                break
              case 20:
                console.log('开关老师摄像头', option.value)
                QWebChannel.objects.videoCallWindow.muteTeacherVideo(
                  option.value
                )
                break
              case 21:
                console.log('进入了邀请页面通知客户端')
                QWebChannel.objects.videoCallWindow.requestQureyTeacherVideoMutability()
                break
            }
          } else {
            const QWebChannel = state.QWebChannel
            QWebChannel.objects.content.receiveText(JSON.stringify(option))
          }
        } catch (e) {
          console.log(e)
        }
      }
    },

    // 登陆后给客户端发送用户信息
    afterLoginSendUserInfo({ dispatch }, newUserInfo) {
      // 获取第一次登录后本地缓存的信息
      let reportLog
      try {
        reportLog = JSON.parse(localStorage.getItem('dk_reportLog'))
      } catch (e) {
        reportLog = null
      }

      // console.log('获取本地缓存', reportLog, newUserInfo)
      const info = {
        type: 5,
        uid: newUserInfo.uid,
        jg_id: newUserInfo.jg_id,
        token: newUserInfo.chatRoom_token,
        account: newUserInfo.chatRoom_creator,
      }
      // 直接发送给客户端uid
      dispatch('sendToWindowsMsg', info)
      // 本地缓存不存在（第一次）
      // 如果不相等说明用户信息发生变化
      if (
        !reportLog ||
        (newUserInfo.uid != reportLog.uid &&
          newUserInfo.jg_id != reportLog.jg_id)
      ) {
        // console.log('发送给客户端消息', info)
        dispatch('sendToWindowsMsg', {
          type: -5,
          jg_id: Number(info.jg_id),
        })
        localStorage.setItem('dk_reportLog', JSON.stringify(info))
      }
    },

    // 跳转地址 兼容客户端
    open({ state, dispatch }, url) {
      if (!url) {
        console.error('跳转无url')
        return
      }
      const newUrl = /^#\//.test(url) ? `${location.origin}/${url}` : url
      if (state.isWindowsApp) {
        dispatch('sendToWindowsMsg', { type: 3, url: newUrl })
      } else {
        window.open(newUrl)
      }
    },

    // 控制台的跳转，兼容跳转类型
    open2({ state, dispatch }, option) {
      if (!option.url) {
        console.error('跳转无url')
        return
      }
      const newUrl = /^#\//.test(option.url)
        ? `${location.origin}/${option.url}`
        : option.url
      if (state.isWindowsApp) {
        dispatch('sendToWindowsMsg', {
          type: option.type,
          url: newUrl,
          id: option.id,
          val: option.val,
        })
      } else {
        window.open(newUrl)
      }
    },

    // 下载
    downLoad({ dispatch, state }, url) {
      if (state.isWindowsApp) {
        dispatch('open', url)
      } else {
        const link = document.createElement('a')
        link.setAttribute('download', '')
        link.href = url
        link.click()
      }
    },

    // obs 推流状态修改
    updateObsSwitchStatus({ commit }, status) {
      axios(
        {
          url: '/User/setObs',
          data: {
            status,
          },
        },
        ({ data: { code } }) => {
          if (Number(code) === 200) {
            // 成功的处理
            commit('setObsPushStream', status == 1)
          }
        }
      ).request
    },

    // 本地设置session
    setLocalSessionAndUserKey() {
      // 本地运行的情况下 去设置session
      if (process.env.NODE_ENV !== 'production') {
        return new Promise(resolve => {
          const script = document.createElement('script')
          script.src = `https://teacher-test.dingdingkaike.com/test/setUserKey?t=${Date.now()}`
          script.onload = resolve
          document.getElementsByTagName('head')[0].appendChild(script)
        })
      }
    },
  },
})

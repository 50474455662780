/*
 * @Descripttion:智能机器热
 * @version: 1.4.25
 * @Author: cyy
 * @Date: 2022-10-18 14:17:18
 * @LastEditors: cyy
 * @LastEditTime: 2022-10-18 14:23:38
 */

const router = {
    path: '/liveRobot',
    name: 'liveRobot',
    component: () => import('@view/liveRobot/liveRobot'),
    meta: { requiresAuth: true, menu_id: 216 },
    // children: [
    //     {
    //         path: 'newData/:id',
    //         name: 'newData',
    //         component: () =>
    //             import('@view/LiveBroadcastManagement/openCourse/newData'),
    //         meta: { pageOption: { name: '新建' } },
    //     },
    //     {
    //         path: 'detail/:id/:type',
    //         name: 'publicClassdetail',
    //         component: () =>
    //             import('@view/LiveBroadcastManagement/openCourse/detail'),
    //         meta: { pageOption: { name: '详情' } },
    //         children: [
    //             {
    //                 path: 'dataStatistics',
    //                 name: 'dataStatistics',
    //                 component: () =>
    //                     import(
    //                         '@view/LiveBroadcastManagement/openCourse/detailcomponents/dataStatistics/dataStatistics'
    //                     ),
    //                 meta: {
    //                     requiresAuth: true,
    //                     pageOption: {
    //                         name: '数据统计',
    //                     },
    //                 },
    //             },

    //             {
    //                 path: 'viewDetail/id/:id',
    //                 component: () => import('@/components/dataAnalysis/viewDetail'),
    //                 meta: {
    //                     requiresAuth: true,
    //                     pageOption: {
    //                         name: '学习详情',
    //                     },
    //                 }, 
    //             },

    //             {
    //                 path: 'playbackManagement',
    //                 name: 'playbackManagement',
    //                 component: () =>
    //                     import(
    //                         '@view/LiveBroadcastManagement/openCourse/detailcomponents/playbackManagement'
    //                     ),
    //                 meta: {
    //                     requiresAuth: true,
    //                     pageOption: {
    //                         name: '回放管理',
    //                     },
    //                 },
    //             },
    //         ],
    //     },
    // ],
}

// prompt: '在当前课程'
export default router

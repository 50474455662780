/**
 *  @description 采集
 */

 const router = {
  path: '/setting/collect',
  name: 'settingCollect',
  component: () => import('@view/schoolSetting/index'),
  meta: { requiresAuth: true, menu_id: 77 }
}
export default router

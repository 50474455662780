<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-05-11 16:06:26
 * @LastEditTime: 2022-05-18 15:22:38
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacher.dingdingkaike.com\src\components\helpIcon.vue
-->
<template>
  <el-popover
    v-if="prompt || content"
    trigger="hover"
    :content="prompt"
    class="popover vm"
    popper-class="digitalDisplay-popper"
  > 
    <template slot="reference">
      <slot name="text"></slot>
      <div class="vm help" :class="{ icon2 }"></div>
    </template>
    <div :class="`${isWhiteSpace?'white-space':''}`" v-if="content" v-html="content"></div>
  </el-popover>
</template>
<script>
export default {
  name: 'helpIcon',

  props: {
    prompt: String,
    content: String,
    icon2: Boolean,
    isWhiteSpace: Boolean,
  },
}
</script>
<style lang="scss" scoped>
.help {
  width: 14px;
  height: 14px;
  cursor: pointer;
  background: url('~@ass/img/1.2.9/ico_wh@2x.png');
  background-size: 100%;
  &.icon2 {
    width: 8px;
    height: 13px;
    background: url('~@ass/img/1.2.9/ico_xljt.png');
    background-size: 100%;
  }
}
.content {
  line-height: 1;
}
.popover {
  font-size: 0;
}
</style>
<style>
.digitalDisplay-popper {
  max-width: 348px;
  line-height: 1.6 !important;
  font-size: 12px !important;
  padding: 7px 14px !important;
}
</style>

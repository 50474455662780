/*
 * @Author: cyy
 * @version: 1.4.17
 * @Date: 2022-06-27 15:38:45
 * @LastEditTime: 2022-08-01 18:15:42
 * @LastEditors: cyy
 * @Description:
 * @FilePath: \teacherdingding.com\src\router\module\teachingAffairsAdmin\membership.js
 */
/**
 *  @description 课程管理-课程会员
 */

const router = {
  path: '/tool/member',
  name: 'membership',
  component: () => import('@view/Survey/membership/membership'),
  meta: { requiresAuth: true, menu_id: 161 },
  children: [
    {
      path: 'newmember',
      name: 'newmember',
      component: () => import('@view/Survey/membership/components/newmember'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '新建课程会员' },
      },
    },
    {
      path: 'memberexamine',
      name: 'memberexamine',
      component: () =>
        import('@view/Survey/membership/components/memberexamine'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '查看会员' },
      },
    },
  ],
}

export default router

/**
 *  @description 权限设置页面
 */

const router = {
  path: '/manageAuth',
  name: 'manageAuth',
  component: () => import('@view/teachingAdmin/purviewSetting'),
  meta: { requiresAuth: true, menu_id: 61 }
}

export default router

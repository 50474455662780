/*
 * @Descripttion:智能直播
 * @version: 1.4.2.3
 * @Author: cyy
 * @Date: 2021-04-19 17:29:22
 * @LastEditors: cyy
 * @LastEditTime: 2021-04-19  18:25:55
 */
/**
 *  @description 智能直播
 */

const router = {
  path: '/intelligence',
  name: 'intelligence',
  component: () => import('@view/LiveBroadcastManagement/openCourse/openClass'),
  meta: {
    requiresAuth: true,
    menu_id: 204,
    alias: '/intelligence',
    aliasName: '智能直播',
  },
  children: [
    {
      path: 'newData/:id',
      name: 'newData',
      component: () =>
        import('@view/LiveBroadcastManagement/openCourse/newData'),
      meta: { pageOption: { name: '新建' } },
    },
    {
      path: 'intelligenceManager',
      name: 'intelligenceManager',
      component: () =>
        import(
          '@view/LiveBroadcastManagement/openCourse/components/intelligenceManager'
        ),
      meta: {
        pageOption: {
          name: '内容管理',
          prompt:
            '提示：直播助手无法播放视频！（添加视频后请勿加密视频，否则无法播放！）',
          iconPrompt: 2,
        },
      },
    },
    {
      path: 'detail/:id/:type',
      name: 'publicClassdetail',
      component: () =>
        import('@view/LiveBroadcastManagement/openCourse/detail'),
      meta: { pageOption: { name: '详情' } },
      children: [
        {
          path: 'dataStatistics',
          component: () =>
            import(
              '@view/LiveBroadcastManagement/openCourse/detailcomponents/dataStatistics/dataStatistics'
            ),
          meta: {
            requiresAuth: true,
            pageOption: {
              name: '数据统计',
            },
          },
        },

        {
          path: 'viewDetail/id/:id',
          component: () => import('@/components/dataAnalysis/viewDetail'),
          meta: {
            requiresAuth: true,
            pageOption: {
              name: '学习详情',
            },
          },
        },

        {
          path: 'playbackManagement',
          name: 'playbackManagement',
          component: () =>
            import(
              '@view/LiveBroadcastManagement/openCourse/detailcomponents/playbackManagement'
            ),
          meta: {
            requiresAuth: true,
            pageOption: {
              name: '回放管理',
            },
          },
        },
      ],
    },
  ],
}

// prompt: '在当前课程'
export default router

<template>
  <div>
    <template v-if="isPosition">
      <fade>
        <div :class="{ iframeCenter: isPosition }" :style="`margin: -${height / 2}px 0 0 -${width / 2}px`">
          <iframe class="iframe" :src="pageSrc" :width="width" :height="height"></iframe>
          <i class="close" @click="close"></i>
        </div>
      </fade>
    </template>
    <template v-else>
      <iframe class="iframe" :src="pageSrc" :width="width" :height="height"></iframe>
    </template>
  </div>
</template>
<script>
import fade from '@/components/FadeAnimation'

export default {
  name: 'Iframe',

  props: {
    src: String,
    isPosition: {
      type: Boolean,
      default: true
    }
  },

  components: {
    fade
  },

  data() {
    return {
      width: 0,
      height: 0,
      pageSrc: this.src
    }
  },

  created() {
    window.onresize = () => {
      const bigScreen = window.innerHeight > 1024
      this.width = bigScreen ? 414 : 375
      this.height = this.isPosition ? (bigScreen ? 896 : 660) : window.innerHeight
    }
    window.onresize()
    // 禁止页面滚动
    if (this.isPosition) {
      document.documentElement.style.overflow = 'hidden'
      document.body.style.overflow = 'hidden'
    }
  },

  destroyed() {
    this.pageSrc = 'about:blank'
    if (this.isPosition) {
      document.documentElement.style.overflow = ''
      document.body.style.overflow = ''
    }
  },

  methods: {
    close() {
      this.$emit('close')
      this.$root.reload()
    }
  }
}
</script>
<style scoped>
.iframeCenter {
  position: absolute;
  left: 50%;
  top: 50%;
}
.iframe {
  border: 0;
  display: block;
  background: #fff;
}
.close {
  width: 30px;
  height: 30px;
  position: absolute;
  right: -47px;
  top: 10px;
  cursor: pointer;
  background: url('../assets/img/ico_close2.png') no-repeat;
}
</style>

/*
 * @Descripttion:公开课
 * @version: 1.4.2.3
 * @Author: cyy
 * @Date: 2021-04-19 17:29:22
 * @LastEditors: cyy
 * @LastEditTime: 2021-04-19  18:25:55
 */
/**
 *  @description 公开课
 */

const router = {
  path: '/publicClass',
  name: 'publicClass',
  component: () => import('@view/LiveBroadcastManagement/openCourse/openClass'),
  // meta:{notLogin: true}
  meta: { requiresAuth: true, menu_id: 201 },
  // meta: { requiresAuth: true, menu_id: 58 }
  children: [
    {
      path: 'newData/:id',
      name: 'newData',
      component: () =>
        import('@view/LiveBroadcastManagement/openCourse/newData'),
      meta: { pageOption: { name: '新建' } },
    },
    {
      path: 'detail/:id/:type',
      name: 'publicClassdetail',
      component: () =>
        import('@view/LiveBroadcastManagement/openCourse/detail'),
      meta: { pageOption: { name: '详情' } },
      children: [
        {
          path: 'dataStatistics',
          name: 'dataStatistics',
          component: () =>
            import(
              '@view/LiveBroadcastManagement/openCourse/detailcomponents/dataStatistics/dataStatistics'
            ),
          meta: {
            requiresAuth: true,
            pageOption: {
              name: '数据统计',
            },
          },
        },

        {
          path: 'viewDetail/id/:id',
          component: () => import('@/components/dataAnalysis/viewDetail'),
          meta: {
            requiresAuth: true,
            pageOption: {
              name: '学习详情',
            },
          },
          // meta: { notLogin: true }
        },

        {
          path: 'playbackManagement',
          name: 'playbackManagement',
          component: () =>
            import(
              '@view/LiveBroadcastManagement/openCourse/detailcomponents/playbackManagement'
            ),
          meta: {
            requiresAuth: true,
            pageOption: {
              name: '回放管理',
            },
          },
        },
      ],
    },
  ],
}

// prompt: '在当前课程'
export default router

<template>
  <FadeAnimation>
    <div class="update-popup">
      <i class="close-btn" @click.stop="close"></i>
      <div class="title-bg"></div>
      <div class="inner-wrap">
        <p class="txt">您当前为{{ versionName }}，升级版本，享受更多特权，如需试用请联系客服</p>
        <a class="update-btn" @click.stop="iframeSrc = '/Wallet/VIPIntroduction'">立即升级</a>
        <p class="updata-txt" @click.stop="iframeSrc = '/Wallet/VIPIntroduction'">
          查看专享特权 >>
        </p>
        <div class="serviceUrl">
          <img :src="serviceUrl" alt="" />
        </div>
      </div>
      <!--内嵌网页-->
      <my-iframe v-if="iframeSrc" :src="iframeSrc" @close="iframeSrc = ''"></my-iframe>
    </div>
  </FadeAnimation>
</template>
<script>
import FadeAnimation from '@/components/FadeAnimation'

export default {
  name: 'updatePopup',

  props: {
    disable: Boolean,
    versionName: String
  },

  components: {
    FadeAnimation
  },

  data() {
    return {
      iframeSrc: ''
    }
  },

  computed: {
    serviceUrl() {
      return this.$store.state.serviceUrl
    }
  },

  methods: {
    close() {
      if (!this.disable) {
        this.$emit('close')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.update-popup {
  position: absolute;
  width: 15rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 0.2rem;
  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    width: 1.6rem;
    height: 1.6rem;
    background: url('~@ass/img/createClass/ico_gb@2x.png') no-repeat center center;
    background-size: 0.6rem 0.6rem;
  }
  .title-bg {
    height: 108px;
    background: url('~@ass/img/createClass/img_peit@2x.png') no-repeat;
    background-size: 100% 100%;
  }
  .inner-wrap {
    padding: 1rem;
    box-sizing: border-box;
    p {
      text-align: center;
      font-size: 0.65rem;
      font-weight: 500;
      line-height: 22px;
      color: rgba(102, 102, 102, 1);
    }
    .txt {
      text-align: left;
    }
    .update-btn {
      display: inline-block;
      width: 100%;
      height: 2rem;
      margin: 1.65rem 0 0.65rem;
      line-height: 2rem;
      font-size: 0.7rem;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      background: linear-gradient(198deg, rgba(255, 202, 115, 1) 0%, rgba(236, 160, 35, 1) 100%);
      box-shadow: 0r 0.2rem 0.4rem 0 rgba(243, 152, 2, 0.28);
      border-radius: 0.2rem;
    }
    .updata-txt {
      color: rgba(10, 162, 155, 1);
    }
    .serviceUrl {
      width: 110px;
      margin: 10px auto 0;
      img {
        width: 100%;
      }
    }
  }
}
</style>

/**
 *  @description 音视频
 */

const router = {
  // audioVideo
  path: '/library/:type',
  name: 'library',
  component: () => import('@view/audioVideo/videoIndex'),
  meta: { requiresAuth: true }
}

export default router

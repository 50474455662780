/*
 * @Descripttion:代金券
 * @version:1.4.4.8
 * @Author: cyy
 * @Date: 2021-11-03 17:29:22
 * @LastEditors: cyy
 * @LastEditTime: 2021-11-03  18:25:55
 */
/**
 *  @description 代金券
 */

const router = {
  path: '/voucher',
  name: 'voucher',
  component: () => import('@view/MarketingCenter/cashCoupon/main'),
  meta: { requiresAuth: true, menu_id: 206 },
  children: [
    {
      path: 'purchaserecord',
      name: 'purchaserecord',
      component: () =>
        import('@view/MarketingCenter/cashCoupon/purchaseRecord'),
      meta: { pageOption: { name: '购买记录' } },
    },
  ],
}

export default router

/*
 * @Author: cyy
 * @version: 
 * @Date: 2022-09-13 18:38:47
 * @LastEditTime: 2022-09-14 10:05:53
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\router\module\marketingCenter\extractionCode.js
 */
/**
 *  @description  提取码
 */

const router = {
    path: '/exchange',
    name: 'exchange',
    component: () => import('@view/MarketingCenter/extractionCode'),
    meta: { requiresAuth: true, menu_id: 215 },
    children: [

        {
            path: 'addExchangeActionList',
            name: 'addExchangeActionList',
            component: () => import('@view/MarketingCenter/addExchangeActionList'),
            meta: { pageOption: { name: '新建提取码' } }
        },
        {
            path: 'editExchangeActionList',
            name: 'editExchangeActionList',
            component: () => import('@view/MarketingCenter/editExchangeActionList'),
            meta: { pageOption: { name: '提取码详情' } }
        }
    ]
}

export default router

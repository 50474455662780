/**
 *  @description 采集
 */

export const router = {
  path: '/setting/paymentSetting',
  name: 'paymentSetting',
  component: () => import('@view/schoolSetting/index'),
  meta: { requiresAuth: true },
  children:[
    {
      path: 'business',
      name: 'business',
      component: () => import('@view/schoolSetting/wechatBusiness'),
      meta: { 
        pageOption: { name: '微信支付开通',isReplace:1 }, 
      }
    },
    {
      path: 'individual',
      name: 'individual',
      component: () => import('@view/schoolSetting/individual'),
      meta: { 
        pageOption: { name: '个人用户模式',isReplace:1 }, 
      }
    },
  ]
}

/**
 *  @description 手机网校页面
 */

const router = {
  path: '/h5School',
  name: 'h5School',
  component: () => import('@view/NetSchollAdmin/PhoneScholl'),
  meta: { requiresAuth: true, menu_id: 2  }
}

export default router

/**
 *  @description 权限回调
 */

const router = {
  path: '/authCallback',
  name: 'authCallback',
  component: () => import('@view/authCallback/authCallback'),
  meta: { requiresAuth: true }
}

export default router

/**
 *  @description 网校账户
 */

const router = {
  path: '/capitalAccount',
  meta: {
    requiresAuth: true,
    menu_id: 154
  },
  component: () => import('@view/AssetAdmin/index'), //网校账户累计总收入：课程+直播打赏金额，包括已过期、已作废订单收入
  children: [
    {
      path: '',
      name: 'SchollAccount',
      component: () => import('@view/AssetAdmin/SchollAccount') //网校账户
    },
    {
      path: 'TopUpDingDou',
      name: 'TopUpDingDou',
      component: () => import('@view/AssetAdmin/TopUpDingDou'),
      meta: { pageOption: { name: '充值' }, menu_id: 138 }
    },
    {
      path: 'TiXian',
      name: 'TiXian',
      component: () => import('@view/AssetAdmin/TiXian'),
      meta: { pageOption: { name: '提现' }, menu_id: 137 }
    },
    {
      path: 'detail',
      name: 'detail',
      component: () => import('@view/AssetAdmin/detail'),
      meta: { pageOption: { name: '详情' } }
    }
  ]
}

export default router

/*
 * @Descripttion:公开课
 * @version: 1.4.2.3
 * @Author: cyy
 * @Date: 2021-04-19 17:29:22
 * @LastEditors: cyy
 * @LastEditTime: 2021-04-19  18:25:55
 */
/**
 *  @description 控制台
 */

const router = {
    path: '/OpenClass_openClassLive/:id?',
    name: 'OpenClass_openClassLive',
    component: () => import('@view/OpenClass_openClassLive/main'),
    meta: { requiresAuth: true, topPage: true },
    // children: [
    //     {
    //         path: 'newData/:id',
    //         name: 'newData',
    //         component: () => import('@view/LiveBroadcastManagement/openCourse/newData'),
    //         meta: { pageOption: { name: '新建' } }
    //     },

    // ]
}

// prompt: '在当前课程'
export default router

<template>
  <fade-animation @parentEvent="cancel">
    <div class="fadeConfirmChild code-wrap">
      <h3>使用手机微信扫码操作<i class="close-btn" @click.stop="cancel"></i></h3>
      <div class="code-pic" ref="qrcode"></div>
      <div class="txt"><i></i>当前客户端不支持此操作，请在手机端操作</div>
    </div>
  </fade-animation>
</template>
<script>
import fadeAnimation from '@/components/FadeAnimation'
import QRCode from '@/assets/js/qrcode'

export default {
  name: 'cannotNav',

  props: {
    currentUrl: {
      type: String,
      default: 'https://teacher-test.dingdingkaike.com/User/index'
    }
  },

  components: {
    fadeAnimation
  },

  mounted() {
    this.createCode()
  },

  methods: {
    cancel() {
      this.$emit('close')
    },

    createCode() {
      new QRCode(this.$refs.qrcode, {
        text: this.currentUrl,
        width: 47 * 3,
        height: 47 * 3,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    }
  }
}
</script>
<style lang="scss">
.code-pic {
  img {
    margin: 0 auto;
  }
}
</style>

<style lang="scss" scoped>
.code-wrap {
  width: 300px;
  background: #fff;
  border-radius: 0.2rem;
  padding-bottom: 1.45rem;
  h3 {
    position: relative;
    padding: 1rem 0;
    font-size: 0.7rem;
    color: rgba(51, 51, 51, 1);
    line-height: 0.95rem;
    text-align: center;
    .close-btn {
      width: 1.6rem;
      height: 1.6rem;
      position: absolute;
      right: 0;
      top: 0;
      background: url('~@ass/img/iphoneView/ico_gb@2x.png') no-repeat center center;
      background-size: 0.6rem 0.6rem;
    }
  }
  .txt {
    position: relative;
    width: 85%;
    max-width: 12rem;
    margin: 1.2rem auto 0;
    padding: 0.3rem 0.8rem 0.3rem 1.9rem;
    background: #ffe4e4;
    font-size: 0.65rem;
    color: rgba(255, 53, 53, 1);
    line-height: 0.85rem;
    border-radius: 0.2rem;
    box-sizing: border-box;
    i {
      position: absolute;
      left: 0.5rem;
      top: 50%;
      width: 0.9rem;
      height: 0.9rem;
      transform: translateY(-50%);
      background: url('~@ass/img/iphoneView/ico_gth@2x.png') no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>

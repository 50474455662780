<!---右下加 客服--->
<template>
  <div v-if="serviceUrl" class="service">
    <div @click.stop="toggleService" class="icon"></div>
    <div @click.stop v-if="showService" class="service-content">
      <div class="img">
        <img :src="serviceUrl" alt="" />
      </div>
      <div class="t1">扫码添加微信客服</div>
      <div class="t2">答疑、咨询、快速开通服务</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'service',

  data() {
    return {
      showService: false
    }
  },

  computed: {
    serviceUrl() {
      return (
        this.$store.state.customJgInfo.serviceUrl ||
        (this.$store.state.customJgInfo.isCustome
          ? ''
          : 'https://img.dingdingkaike.com/pc/home/static/img/Screenshot_2019-02-23-11-06-46-364_微信.jpg?imageslim')
      )
    }
  },

  created() {
    window.addEventListener('click', () => {
      this.showService = false
    })
  },

  methods: {
    toggleService() {
      this.showService = !this.showService
    }
  }
}
</script>
<style lang="scss" scoped>
.service {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 6;
  .icon {
    width: 30px;
    height: 112px;
    cursor: pointer;
    background: url('~@ass/img/1.1.8/img_kffk.png') no-repeat;
  }
  .service-content {
    width: 238px;
    height: 230px;
    position: absolute;
    right: 30px;
    bottom: 0;
    user-select: none;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.18);
    border-radius: 3px;
    border: 1px solid rgba(237, 237, 237, 1);
    .img {
      width: 130px;
      height: 130px;
      margin: 22px auto;
      img {
        width: 100%;
        display: block;
      }
    }
    .t1,
    .t2 {
      font-size: 14px;
      text-align: center;
      line-height: 20px;
      color: rgba(51, 51, 51, 1);
    }
  }
}
</style>

<template>
  <div class="user-info" :class="{ boxShadow: true }">
    <div class="title" ref="header-info">
      <div
        class="flex-center item"
        v-for="(item, index) in childPageOptions"
        :key="item.path"
        :class="{ 'in-gray': index != childPageOptions.length - 1 }"
      >
        <i v-if="index != 0" class="right-arrow"></i>
        <span @click="switchRouter(item)" class="path">
          {{ item.name }}
        </span>
        <!--右侧的小提示 ?-->
        <template v-if="item.prompt">
          <span
            class="prompt"
            v-if="item.iconPrompt == 2"
            :style="item.color ? `color: ${item.color}` : ''"
          >
            {{ item.prompt }}
          </span>
          <helpIcon
            v-else
            style="margin-left: 6px"
            :content="item.prompt"
          ></helpIcon>
        </template>
        <template>
          <div class="decribe"></div>
        </template>
      </div>
    </div>
    <div class="info">
      <el-tooltip effect="dark" content="火眼猫AI" placement="bottom">
        <img class="nav2 nav3" src="~@ass/img/1.4.47/icon_fire_cat.png" alt="" @click="showAi38k"/>
      </el-tooltip>
      <div
        v-if="!customJgInfo.isCustome"
        @click="locationHelpPage"
        class="vt nav2"
      >
        帮助中心
      </div>
      <div v-if="!isWindowsApp" @click="goHomePage" class="vt nav2">
        返回首页
      </div>
      <!-- <div class="vt space"></div> -->
      <!-- <div class="vt nav1" @click.stop="toggleWxConfirm">手机后台</div> -->
      <!-- <div class="vt nav1" @click="getMsg">消息通知</div> -->
      <div @click.stop="clickUser" class="vt user">
        <div class="vm name">
          <!-- <div class="t1">{{ userInfo.uname }}</div> -->
          <div class="t2"></div>
        </div>
        <div class="vm photo">
          <img
            src="../../assets/img/schoolInfo/ico_mrtx@2x.png"
            v-if="!userInfo.uphoto"
          />
          <img v-else :src="userInfo.uphoto" />
        </div>
      </div>
      <!--用户设置 比如退出-->
      <div v-if="userSettingConfirm" class="setting-user">
        <div class="cp pop-content" @click="toPersonalCenter">
          <div class="inner">
            <div class="user-head">
              <img
                src="../../assets/img/schoolInfo/ico_mrtx@2x.png"
                v-if="!userInfo.uphoto"
              />
              <img v-else :src="userInfo.uphoto" />
            </div>
            <div class="detail">
              <div class="t2">
                <h4>{{ userInfo.uname }}</h4>
                <div class="mobile-number">{{ userInfo.umobile }}</div>
              </div>
            </div>
          </div>
          <i class="arrow"></i>
        </div>
        <div class="item" @click.stop="switchSchool">切换网校</div>
        <div class="item" @click.stop="exit">退出登录</div>
      </div>
      <!--手机后台-->
      <div v-show="showWxConfirm" class="phone-wx">
        <div class="title">扫码进入手机后台管理</div>
        <div class="code" ref="qrcode"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { logOut } from '@ass/js/cookie'
import QRCode from '@ass/js/qrcode'
import helpIcon from '@/components/helpIcon'
export default {
  name: 'UserInfo',

  components: {
    helpIcon,
  },

  props: {
    childPageOptions: Array,
  },

  data() {
    return {
      showWxConfirm: false,
      userSettingConfirm: false,
      deBug: false,
    }
  },
  // watch: {
  //   $route(to) {
  //     if (to.meta.alias && to.path == to.meta.alias) {
  //       this.alias = to.meta.alias
  //       console.log(this.$route.path, to)
  //     }
  //   },
  // },
  computed: {
    ...mapState(['userInfo', 'currRouterPage', 'isWindowsApp', 'customJgInfo']),

    // showBoxShadow() {
    //   return this.$store.state.menuList.filter(
    //     item => item.path === this.$route.path && item.showBoxShadow
    //   )[0]
    // },
  },
  methods: {
    bindGlobalClick() {
      document.body.addEventListener('click', this.removeGlobalClick)
    },

    removeGlobalClick() {
      this.showWxConfirm = false
      this.userSettingConfirm = false
      document.body.removeEventListener('click', this.removeGlobalClick)
    },

    toggleWxConfirm() {
      this.showWxConfirm = !this.showWxConfirm
      if (this.showWxConfirm && !this.qrcode) {
        this.$nextTick(() => {
          this.createQRCode()
        })
      }
      if (this.showWxConfirm) {
        this.bindGlobalClick()
      }
    },

    // 跳转帮助页面
    locationHelpPage() {
      this.$store.dispatch('open', this.userInfo.helpPageUrl)
    },
    //跳转修改密码页
    toPersonalCenter() {
      this.$router.push({
        path: '/AccountSettings',
      })
    },
    // 点击消息通知
    getMsg() {
      this.$root.toggleProductUpdate()
    },

    // 创建手机网校二维码
    createQRCode() {
      this.qrcode = !!new QRCode(this.$refs.qrcode, {
        text: this.userInfo.teacherDomain,
        width: 138,
        height: 138,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      })
    },

    // 用户设置
    clickUser() {
      this.userSettingConfirm = !this.userSettingConfirm
      if (this.userSettingConfirm) {
        this.bindGlobalClick()
      }
    },

    // 切换路由
    switchRouter(item) {
      this.$router.push(item.path)
    },

    // 跳转首页
    goHomePage() {
      this.userSettingConfirm = false
      this.$router.push({
        path: this.$store.getters.homePath,
      })
    },

    // 切换网校
    switchSchool() {
      this.$router.push({
        path: '/switchSchool',
      })
    },

    // 退出登录
    exit() {
      // 清除客户端cookie
      if (this.isWindowsApp) {
        try {
          this.$store.dispatch('sendToWindowsMsg', {
            type: 4,
          })
        } catch (e) {
          console.log(e)
        }
      }
      // 也清除浏览器cookie
      // this.$store.commit("setUserInfo");
      logOut()
    },
    showAi38k(){
      this.$emit('showAi38k')
    }
  },
}
</script>
<style lang="scss" scoped>
.user-info {
  $height: 60px;
  font-size: 0;
  height: $height;
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  left: $menuWidth;
  &.boxShadow {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.04);
  }
  .title {
    display: flex;
    align-items: center;
    color: #333;
    padding: 0 20px;
    font-size: 17px;
    font-weight: 500;
    line-height: $height;
    .item {
      &.in-gray {
        color: #9b9b9b;
      }
      .right-arrow {
        display: inline-block;
        width: 6px;
        height: 10px;
        margin: 0 10px;
        background: url('~@ass/img/menu/ico_zk.png') no-repeat center/100% 100%;
      }
      .path {
        cursor: pointer;
      }
      .prompt {
        font-size: 12px;
        line-height: 16px;
        margin-left: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        color: #ff3535;
      }
      .describe {
        margin-left: 30px;
        font-size: 13px;
        color: #ff3535;
        line-height: 13px;
      }
    }
  }
  .info {
    position: absolute;
    right: 20px;
    top: 0;
    height: $height;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    user-select: none;
    .nav1 {
      cursor: pointer;
      color: #666666;
      font-size: 13px;
      line-height: 17px;
    }
    .space {
      height: 12px;
      cursor: pointer;
      margin: 2px 13px 0;
      border-right: 1px solid #999;
    }
    .nav2 {
      cursor: pointer;
      color: #666666;
      font-size: 13px;
      line-height: 60px;
      text-decoration: none;
      margin-left: 15px;
      &:hover {
        text-decoration: underline;
      }
    }
    .nav3 {
      position: relative;
      cursor: pointer;
      width: 34px;
    }
    .user {
      width: 60px;
      position: relative;
      text-align: center;
      cursor: pointer;
      .name {
        position: absolute;
        left: 50%;
        bottom: -6px;
        transform: translateX(-50%);
        // margin: 0 10px 0 34px;
        .t1 {
          color: #333;
          font-size: 13px;
          line-height: 17px;
        }
        .t2 {
          margin-top: 4px;
          text-align: right;
          i {
            width: 25px;
            height: 13px;
            display: inline-block;
            &.tag1 {
              background: url('https://img.dingdingkaike.com/teacher.dingdingkaike/icon_jgAdmin.png')
                no-repeat center center;
            }
            &.tag0 {
              background: url('https://img.dingdingkaike.com/teacher.dingdingkaike/icon_teacher.png')
                no-repeat center center;
            }
          }
        }
      }
      .photo {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 2px solid rgba(230, 230, 230, 1);
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
/**用户设置**/
.setting-user {
  width: 262px;
  position: absolute;
  top: 59px;
  right: 19px;
  z-index: 3;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.19);
  border-radius: 2px;
  .item {
    padding: 20px 0;
    cursor: pointer;
    font-size: 15px;
    text-align: center;
    color: rgba(74, 74, 74, 1);
    border-bottom: 1px solid #f1f1f1;
    &:hover {
      background: #f6f6f6;
    }
  }
}
/**手机后台**/
.phone-wx {
  width: 212px;
  height: 212px;
  border-radius: 4px;
  position: absolute;
  top: 35px;
  right: 173px;
  z-index: 3;
  padding: 18px 0 17px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(233, 233, 233, 1);
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.08);
  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: rgba(106, 106, 106, 1);
  }
  .code {
    width: 138px;
    height: 138px;
    margin: 19px auto 0;
    * {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
.pop-content {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #f1f1f1;
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .detail {
    h4 {
      font-size: 15px;
      line-height: 20px;
      padding-right: 10px;
      color: rgba(74, 74, 74, 1);
    }
    .mobile-number {
      font-size: 12px;
      color: #999999;
      margin-top: 0.5rem;
    }
    i {
      width: 25px;
      height: 13px;
      display: inline-block;
      &.tag1 {
        background: url('https://img.dingdingkaike.com/teacher.dingdingkaike/icon_jgAdmin.png')
          no-repeat center center;
      }
      &.tag0 {
        background: url('https://img.dingdingkaike.com/teacher.dingdingkaike/icon_teacher.png')
          no-repeat center center;
      }
    }
  }
  .user-head {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
    flex-shrink: 0;
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .arrow {
    display: inline-block;
    width: 6px;
    height: 10px;
    background: url('~@ass/img/menu/ico_zk.png') no-repeat center/100% 100%;
  }
}
</style>

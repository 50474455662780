/*
 * @Descripttion:直播带货
 * @version:1.4.4.7
 * @Author: cyy
 * @Date: 2021-11-01 17:29:22
 * @LastEditors: cyy
 * @LastEditTime: 2021-11-01  18:25:55
 */
/**
 *  @description 直播带货
 */


const router = {
    path: '/commerce',
    name: 'commerce',
    component: () => import('@view/LiveBroadcastManagement/commerce/main'),
    meta: { requiresAuth: true, menu_id: 205 },
    children: [
        {
            path: 'commerceOder/:id?',
            name: 'commerceOder',
            component: () => import('@view/LiveBroadcastManagement/commerce/commerceOder'),
            meta: { pageOption: { name: '订单' } }
        },

    ]
}

export default router
/*
 * @Descripttion:工具类
 * @version: 1.1pcMenu
 * @Author: ivan
 * @Date: 2019-11-08 15:09:01
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-12-15 14:38:48
 */
const tool = {
  // 获取地址栏参数
  getUrlParam: function(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
    var r = window.location.search.substr(1).match(reg)
    if (r != null) return unescape(r[2])
    return null
  },
  getViewPortHeight: function() {
    return document.documentElement.clientHeight || document.body.clientHeight
  },
  contains: function(a, obj) {
    let i = a.length
    while (i--) {
      if (a[i] === obj) {
        return i
      }
    }
    return false
  },
  toFixed: function(num, s) {
    let dot = num.indexOf('.')
    if (dot > -1) {
      num = num.slice(0, dot + s + 1)
    }
    return Number(num)
  },
  detailText: function(str) {
    let result = str
    const value = /^\[ddkk_img\](.*)/.exec(str)
    if (value && value[1]) {
      try {
        result = value[1]
          .split(',')
          .map(item => `<img src="${item}">`)
          .join(' ')
      } catch (e) {
        result = ''
      }
    }
    return result
  },

  urlParams() {
    let url = location.href.split('?')[1]
    let urlData = {}
    if (url) {
      let temp = url.split('&')
      for (let i = 0; i < temp.length; i++) {
        let t = temp[i].split('=')
        urlData[t[0]] = decodeURI(t[1]) || ''
      }
    }
    return urlData
  },
  // 格式化时间
  formatTimeStamp(time, format) {
    if (!time) {
      return ''
    }
    if (isNaN(time)) {
      return ''
    }
    format = format || 'yyyy-MM-dd hh:mm:ss'
    let date = new Date(time * 1000)
    let o = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(), // 日
      'h+': date.getHours(), // 小时
      'm+': date.getMinutes(), // 分
      's+': date.getSeconds() // 秒
    }
    if (/(y+)/.test(format) || /(Y+)/.test(format)) {
      format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (let k in o) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
      }
    }
    return format
  },
  // 数组去重
  arrFilter(){}
}
export default tool

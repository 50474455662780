import axios from '@/request/axios'

const state = {
  // 跳转的位置
  openclassDatastatisticsActiveval: null,
  // 点击连麦图标跳转的位置
  rightActiveName: '',
  // 右边问答，私信、提问状态切换
  rightRadio4:1,
  // 是否允许连麦
  lianmaiSwitchValue: '2',
  // 请求连麦列表
  studentRtcJoin: [],
  // 是否是右侧引用
  rightQuote: false,
  // 问答新消息提示
  newtip: false,
  // 新私信消息数量
  newPriMsgs:0,
  // 新问答消息数量
  newQAMsgs:0,
  questionList: [],
  // ispickQuestionlist: false,
  // 被引用的数据
  quotelist: {},
  privateQuotelist: {},
  otherfnPrivateletterselectVal: {},
  // 私信列表
  friendList: [],
  tim: null,
  openClassId: '',
  // 右侧开关
  rightOpen: true,
  consoleInfostatus: false,
  //敏感词
  sensitiveWords: [],
  //用户信息
  userInfo: {},
  //直播信息
  liveInfo: {},
  controlInfo: {},
  //公开课信息
  openInfo: {},
  // 成员列表
  members: {},
  membersMenu: 1,
  memberOnline: 0,
  // 选中成员
  currMemberInfo: {},
  // 群发成员信息
  allMembers:[],

  //放大页面
  openAndClose: false,

  //窗口大小
  innerHeight: 0,
  innerWidth: 0,

  //默认选中签到记录
  defaultSelectState: 1,

  // 签到弹窗
  qiandaoDialogShow: false,

  // 更改标识 1 全屏 或者 2 页面隐藏 0 正常
  stopDisplayMsg: 0,

  // 鼠标移入移出
  isMouseleavePage: false,

  // 自定义消息列表
  customMessage: [],

  // 所有助教成员
  allTutorList:[],
  // 与所有助教的私信历史
  tutorHistorys:{},
}

const getters = {
  chatFullScreen(state) {
    return (
      state.isMouseleavePage &&
      !state.openAndClose &&
      state.rightActiveName == 7
    )
  },
}

const mutations = {
  // 控制台跳转数据统计模块
  openclassDatastatisticsActive(state, val) {
    state.openclassDatastatisticsActiveval = val
  },
  // 改变右侧开关状态
  changerightOpen(state, val) {
    state.rightOpen = val
  },
  // 更改右侧tab中的activeName值
  changerightActiveName(state, val) {
    state.rightActiveName = val
  },
  changerightRadio4(state, val) {
    state.rightRadio4 = val
  },

  // 获取连麦状态
  lianmaistatus(state, val) {
    state.lianmaiSwitchValue = val
    // if (val == 2) {
    //   state.studentRtcJoin = []
    // }
  },

  // 请求连麦的列表
  hadstudentRtcJoin(state, { msg, add, isrecived }) {
    // 请求连麦列表
    if (add) {
      const index = state.studentRtcJoin.findIndex(
        item => item.from == msg.from
      )
      if (index > -1) {
        state.studentRtcJoin.splice(index, 1)
      }
      state.studentRtcJoin.push({
        avatar: msg.avatar,
        nick: msg.nick,
        from: msg.from,
        type: msg.payload.description,
      })
    } else {
      const index = isrecived
        ? state.studentRtcJoin.findIndex(
          item => item.from == JSON.parse(msg.payload.extension).userID
        )
        : state.studentRtcJoin.findIndex(item => item.from == msg.from)
      if (index > -1) {
        state.studentRtcJoin.splice(index, 1)
      }
    }
  },

  // 鼠标移出移出事件
  mousePageEvent(state, val) {
    state.isMouseleavePage = val
  },

  setTim(state, val) {
    state.tim = val
  },

  // 设置自定义消息
  setCustomMessage(state, msg) {
    if (msg === undefined) {
      state.customMessage = []
    } else {
      state.customMessage.push(msg)
    }
  },

  // 设置最大数量自定义消息
  setMaxCustomMessageCout(state) {
    state.customMessage.slice(-30)
  },

  resetData(state) {
    state.userInfo = {}
    //直播信息
    state.liveInfo = {}
    //直播信息
    state.controlInfo = {}
    //公开课信息
    state.openInfo = {}
    // 成员列表
    state.members = {}
    // 选中成员
    state.currMemberInfo = {}
  },

  // 新消息提醒
  newtipChange(state, val) {
    state.newtip = val
  },

  // 私聊数量
  newPriMsgsChange(state, val) {
    state.newPriMsgs = val
  },

  // 问答数量
  newQAMsgsChange(state, val) {
    state.newQAMsgs = val
  },

  // 签到弹窗
  qiandaoDialog(state, val) {
    state.qiandaoDialogShow = val
  },

  // 更改标识 全屏 或者 页面隐藏
  setStopDisplayMsg(state, val) {
    state.stopDisplayMsg = val
  },

  changeOpenClassId(state, val) {
    state.openClassId = val
  },

  // 提问的数据
  changeQuestionList(state, val) {
    state.questionList = val
  },

  // 去获取提问消息，群组3中
  // pickQuestionlist(state) {
  //   state.ispickQuestionlist = !state.ispickQuestionlist
  //   state.questionList = []
  // },

  changerightQuote(state, val) {
    state.rightQuote = val
  },

  chageQuoteval(state, val) {
    state.quotelist = val
  },
  chageprivateQuotelist(state, val) {
    state.privateQuotelist = val
  },

  // 选择的私信人的相关信息
  changeotherfnPrivateletterselectVal(state, val) {
    if (val && Object.keys(val).length > 0) {
      if (val.from) {
        // 或为空的原因：当删除好友时，会触发好友列表更行，回调val为删除的数据，但与好友列表对比是，发现没有，会undefind或者null
        state.otherfnPrivateletterselectVal =
          state.friendList.filter(item => item.userID == val.from)[0] || {}
      } else if (val.profile) {
        state.otherfnPrivateletterselectVal = val
      } else {
        state.otherfnPrivateletterselectVal =
          state.friendList.filter(item => item.userID == val.userID)[0] || {}
      }
    } else {
      state.otherfnPrivateletterselectVal = val
    }
  },

  // 更新私信列表
  chageFriednlist(state, val) {
    state.friendList = val
  },

  // 获取最后一条聊天记录
  addFriendlist(state, val) {
    let arr = _.assign([], state.friendList)
    val.forEach(item => {
      if (item.type == 'C2C') {
        let index = arr.findIndex(i => i.userID == item.userProfile.userID)
        if (index > -1) {
          arr[index].messageForShow = item.lastMessage.messageForShow
        }
      }
    })
    state.friendList = arr
  },

  // 设置成员列表
  setMemberList(state, item) {
    // 全部替换
    state.members = item
  },

  setMemberOnline(state, val) {
    state.memberOnline = val
  },

  //更改鲜花的个数
  setFlower(state, val = 1) {
    state.openInfo.flower_num = Number(state.openInfo.flower_num) + val
  },

  //更改选中成员
  setCurrMember(state, val) {
    // state.currMemberInfo = Object.assign(
    //   {},
    //   state.members[val.from || val.userID]
    // )
    state.currMemberInfo = val
  },

  // 设置群发成员
  setallMembers(state,val){
    state.allMembers = val
  },

  // 设置所有助教成员
  setallTutorList(state,val){
    state.allTutorList = val
  },

  // 设置当前学员与所有助教的聊天记录
  settutorHistorys(state,val){
    state.tutorHistorys = val
  },

  // 选中菜单
  setMembersMenu(state, val) {
    state.membersMenu = val
  },

  setOpenAndClose(state, val) {
    if (val) {
      state.openAndClose = true
    } else {
      if (state.openAndClose == true) {
        state.openAndClose = false
      } else {
        state.openAndClose = true
      }
    }
  },

  //设置window内容宽高
  setInnerHeightWidth(state, option) {
    state.innerHeight = option.innerHeight
    state.innerWidth = option.innerWidth
  },

  //设置签到记录
  setDefaultSelectState(state, opt) {
    state.defaultSelectState = opt
  },
}

const actions = {
  consoleInfo({ state }, open_class_id) {
    state.openClassId = open_class_id
    const request = axios(
      {
        name: 'consoleInfo',
        url: '/console/consoleInfo',
        data: {
          open_class_id: open_class_id || state.openClassId,
        },
      },
      ({ data: { code, data, info } }) => {
        if (code == 200) {
          const {
            sensitiveWords,
            userInfo,
            liveInfo,
            openInfo,
            controlInfo,
          } = data
          state.sensitiveWords = sensitiveWords
          state.userInfo = userInfo
          state.liveInfo = liveInfo
          state.controlInfo = controlInfo
          state.openInfo = openInfo
          state.consoleInfostatus = false
          // dispatch('getliveSet', open_class_id)
        } else {
          state.consoleInfostatus = info || true
        }
      },
      () => {
        console.log('出错了')
      }
    ).request
    // 进行获取请求初次赋值 防止出现多次请求
    // commit('setUserInfoRequest', request)
    return request
  },

  // 获取连麦的数据
  getliveSet({ commit }, val) {
    axios(
      {
        url: `/openClass/liveSet?open_class_id=${val}`,
      },
      ({ data: { code, data } }) => {
        if (code == 200) {
          // state.lianmaiSwitchValue = data.is_show_rtc
          commit('lianmaistatus', data.is_show_rtc)
        } else {
          console.log('出错了')
        }
      },
      () => {
        console.log('出错了')
      }
    )
  },

  sendToWindowsSizeInOpenClass({ rootState }, option) {
    try {
      const QWebChannel = rootState.QWebChannel
      QWebChannel.objects.webText.onResize(option.width, option.height)
    } catch (e) {
      console.log(e)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

/*
 * @Author: cyy
 * @version: 1.4.17
 * @Date: 2022-06-27 15:38:45
 * @LastEditTime: 2022-08-02 11:22:45
 * @LastEditors: cyy
 * @Description:
 * @FilePath: \teacherdingding.com\src\router\module\teachingAffairsAdmin\manageSuperMember.js
 */
/**
 *  @description 教务管理-超级会员
 */

const router = {
  path: '/manageSuperMember',
  name: 'manageSuperMember',
  component: () => import('@view/Survey/manageSuperMember/superMumber'),
  meta: { requiresAuth: true, menu_id: 161 },
  children: [
    {
      path: 'newSupermember',
      name: 'newSupermember',
      component: () =>
        import('@view/Survey/manageSuperMember/components/newSupermember'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '新建超级会员' },
      },
    },
    {
      path: 'surperDetail',
      name: 'surperDetail',
      component: () =>
        import('@view/Survey/manageSuperMember/components/surperDetail'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '详情' },
      },
    },
  ],
}

export default router

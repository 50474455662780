/**
 *  @description 学生账号页面
 */

const router = {
  path: '/studentAccount',
  name: 'studentAccount',
  component: () => import('@view/teachingAdmin/studentAccount'),
  meta: {
    requiresAuth: true,
    menu_id: 67
  }
}

export default router

/**
 *  @description 裂变海报
 */

const router = {
  path: '/marketingFission',
  name: 'marketingFission',
  component: () => import('@view/MarketingCenter/fissionPoster'),
  meta: {
    requiresAuth: true, menu_id: 156
  },
  children: [
    {
      path: 'newActivity/:id',
      name: 'newActivity',
      component: () => import('@view/MarketingCenter/newActivity'),
      meta: { pageOption: { name: '新建活动' } }
    },
    {
      path: 'promotionRecord/:id',
      name: 'promotionRecord',
      component: () => import('@view/MarketingCenter/promotionRecord'),
      meta: { pageOption: { name: '推广记录' } }
    },
  ]
}

export default router

/*
 * @Author: cyy
 * @version: 
 * @Date: 2022-08-30 15:37:02
 * @LastEditTime: 2022-08-31 15:56:45
 * @LastEditors: cyy
 * @Description: 图文
 * @FilePath: \teacherdingding.com\src\router\module\courseAdmin\graphic.js
 */
const router = {
    path: '/graphic',
    name: 'graphic',
    component: () => import('@view/graphic/main'),
    meta: { requiresAuth: true, menu_id: 214 },
    children: [
        {
            path: 'newGraphic',
            name: 'newGraphic',
            component: () => import('@view/graphic/components/newGraphic'),
            meta: {
                requiresAuth: true,
                pageOption: { name: '新建图文' }
            }
        },
        {
            path: 'detailGraphic',
            name: 'detailGraphic',
            component: () => import('@view/graphic/components/detailGraphic'),
            meta: {
                requiresAuth: true,
                pageOption: { name: '详情' },
                menu_id: 197
            }
        }
    ]
}

export default router

<template>
  <div id="app" :class="{
    adminPage: isLogin && !isTopPage,
    rootWindowsAppV2: $store.state.isWindowsAppV2,
  }">
    <!--如果是聊天室页面就不需要显示了-->
    <header-message></header-message>
    <template v-if="isLogin && !isChatrRoom && !isTopPage && !($route.fullPath === '/' && !$route.name)">
      <!--左侧菜单-->
      <menu-list v-show="!$store.state.isWindowsAppV2"></menu-list>
      <!--右侧学生信息-->
      <userInfo :childPageOptions="childPageOptions" :class="{ rootWindowsAppV2: $store.state.isWindowsAppV2 }"
        @showAi38k="changeShowAi38k"></userInfo>
    </template>
    <!--升级版本提示-->
    <updatePopup v-if="showUpdatePopup" :versionName="versionName" :disable="updatePopupDisable"
      @close="toggleUpdatePopup">
    </updatePopup>
    <!--错误提示信息-->
    <errorMessage v-if="error" :errorMessage="error"></errorMessage>
    <!--右下角客服 / 如果是手机登录就不显示-->
    <setvice v-if="!_isPhone && !isTopPage"></setvice>
    <!--广告-->
    <advertising v-if="!isTopPage"></advertising>
    <!-- 针对新用户的弹窗广告 -->
    <newuser-advertising v-if="!isTopPage"></newuser-advertising>
    <!-- ai38k -->
    <AiIcon @showAi38k="changeShowAi38k" v-if="isShowAiIcon"></AiIcon>
    <!--产品升级提示-->
    <confirm v-if="productWillUpdate" @makeSubmit="toggleProductUpdate" content="该模块将在后续产品升级中开放，感谢您的支持！" btnTxt="好的">
    </confirm>
    <confirm v-if="isShowLimit" @makeSubmit="toggleShowLimit" content="当前文件超出资料库剩余空间，无法上传，请升级版本或联系客服获取解决方案"
      btnTxt="知道了">
    </confirm>
    <!--视图-->
    <router-view :key="key" v-if="loaded" />
  </div>
</template>
<script>
import menuList from '@cm/base/MenuList'
import userInfo from '@cm/base/UserInfo'
import updatePopup from '@cm/base/UpdatePopup'
import errorMessage from '@cm/base/ErrorMessage'
import confirm from '@cm/base/newConfirm'
import setvice from '@cm/base/service'
import advertising from '@cm/base/Advertising'
import newuserAdvertising from '@cm/base/newuserAdvertising'
import headerMessage from '@cm/base/headerMessage'
import AiIcon from '@cm/base/AiIcon'
import { openAiWindow } from './utils/index'

export default {
  name: 'App',

  components: {
    setvice,
    menuList,
    userInfo,
    updatePopup,
    errorMessage,
    advertising,
    confirm,
    headerMessage,
    newuserAdvertising,
    AiIcon,
  },

  data() {
    const _myRouteList = JSON.parse(localStorage.getItem('_myRouteList'))
    return {
      //判断是否是手机端观看
      isphone: false,
      showUpdatePopup: false,
      updatePopupDisable: false,
      productWillUpdate: false,
      childPageOptions: _myRouteList ? _myRouteList : [],

      // 加载页面
      loaded: true,
      isShowLimit: false,
    }
  },

  computed: {
    key() {
      // 只要保证 key 唯一性就可以了，保证不同页面的 key 不相同
      // console.log(this.$route.fullPath)
      return this.$route.fullPath
    },
    // 是否登录
    isLogin() {
      return (
        this.$store.state.userInfo.isLogin && this.$store.state.userInfo.jg_id
      )
    },

    // 版本名称
    versionName() {
      return this.$store.state.userInfo.versionName
    },

    currRouterPage() {
      return this.$store.state.currRouterPage
    },

    error() {
      return this.$store.state.errorMessage
    },

    // 是否是聊天室
    isChatrRoom() {
      return /chatrRoom=/i.test(window.location.hash)
    },

    // 就是没有左侧菜单 完整显示主体页面
    isTopPage() {
      return this.$route.meta.topPage
    },
    isShowAiIcon() {  
      const routeList = ['/login'];  
      const currentPath = this.$route.path;  
      const isShow = routeList.includes(currentPath);  
      return !isShow;  
    },
  },

  watch: {
    $route(to) {
      // console.log(to, from)
      // 如果是一级路由
      if (to.fullPath === this.currRouterPage.path) {
        this.childPageOptions = [this.currRouterPage]
      } else {
        // 如果是特殊的页面 比如没权限404
        if (!this.currRouterPage.path) {
          this.childPageOptions = []
        } else if (
          this.childPageOptions.length > 0 &&
          this.childPageOptions[0].path !== this.currRouterPage.path
        ) {
          // 如果是直接复制链接 去别的模块子页面
          // console.log(this.childPageOptions, this.currRouterPage)
          this.childPageOptions = [this.currRouterPage]
        }
        // 否则就是在相同父子路由中跳转
        this.addRoute(_.assign({}, to.meta.pageOption, { path: to.fullPath }))
      }
    },
  },

  methods: {
    toggleShowLimit() {
      this.isShowLimit = !this.isShowLimit
    },

    // 添加路由
    addRoute(route) {
      let routeIndex
      if (
        (routeIndex = this.childPageOptions.findIndex(
          item => item.path === route.path
        )) === -1
      ) {
        // 路由替换
        if (route.isReplace) {
          this.childPageOptions = this.childPageOptions.slice(0, route.isReplace).concat(route)
        } else {
          // 路由进栈
          this.childPageOptions.push(route)
        }
      } else {
        this.childPageOptions = this.childPageOptions.slice(0, routeIndex + 1)
      }
      localStorage.setItem(
        '_myRouteList',
        JSON.stringify(this.childPageOptions)
      )
    },

    // 提示一些信息
    prompt(msg) {
      let option = {
        type: 'error',
        showClose: true,
        customClass: 'el-prompt',
      }
      if (typeof msg === 'object') {
        option = _.assign({}, option, msg, { message: msg.msg || msg.message })
      } else {
        option.message = msg
      }
      if (this._message) {
        this._message.close()
        this._message = null
      }
      this._message = this.$message(option)
    },

    // 版本升级提示
    toggleUpdatePopup(disable) {
      this.updatePopupDisable = typeof disable === 'boolean' ? disable : false
      this.showUpdatePopup = !this.showUpdatePopup
      if (!this.showUpdatePopup) {
        this.updatePopupDisable = false
      }
    },

    // 产品升级提示
    toggleProductUpdate() {
      this.productWillUpdate = !this.productWillUpdate
    },

    // 重新加载页面
    reload() {
      this.loaded = false
      this.$nextTick(() => {
        this.loaded = true
      })
    },
    // 打开ai窗口
    changeShowAi38k() {
      if (this.AiWindow) {
        this.AiWindow.close()
        this.AiWindow = false
      }
      this.AiWindow = openAiWindow()
    },
  },
}
</script>
<style lang="scss">
#app {
  min-height: inherit;
}

// 登录之后 后台管理特有的CSS
.adminPage {
  position: relative;
  padding-top: 60px;
  margin-left: $menuWidth;
  box-sizing: border-box;
}

.rootWindowsAppV2 {
  left: 0 !important;
  margin-left: 0 !important;
}

.windowsApp::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.windowsApp::-webkit-scrollbar,
.windowsApp::-webkit-scrollbar-thumb,
.windowsApp::-webkit-scrollbar-track-piece {
  height: 0;
  background-color: transparent;
}
</style>

<template>
  <div v-if="msg" class="headerMessage">
    <div class="headerMessageBox">
      <a><img src="../../assets/img/icon_tz.png" alt="" /></a>
      <!-- <p>{{ headerMessageInfo.title }}</p> -->
      <p
        :title="headerMessageInfo.title + ':' + headerMessageInfo.introduction"
      >
        {{ headerMessageInfo.title }} : {{ headerMessageInfo.introduction }}
      </p>
      <span title="查看详情" @click="dialogVisible = true">查看详情</span>
    </div>

    <el-dialog
      :title="headerMessageInfo.title"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="568px"
    >
      <div class="headerMessageDialogBox">
        <div class="contenttt">
          <div
            class="dialogVisible_container"
            v-html="headerMessageInfo.text"
          ></div>
        </div>
      </div>
    </el-dialog>
  </div>
  <!-- <div v-html="headerMessageInfo.text"></div> -->
</template>
<script>
export default {
  data() {
    return {
      msg: false,
      headerMessageInfo: {},
      dialogVisible: false,
      scroll: '',
    }
  },
  async mounted() {
    window.addEventListener('scroll', this.handleScroll)
    await this.$bus.$on('cyy', (data) => {
      this.headerMessageInfo = data.notice
      if (Object.keys(this.headerMessageInfo).length == 0) {
        this.msg = false
      } else {
        this.msg = true
      }
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    msg(val) {
      try {
        // if (Object.keys(this.headerMessageInfo).length != 0) {
        var leftMenu = document.querySelector('.menuList-contain')
        var header = document.querySelector('.user-info')
        var content = document.querySelector('.school-wrap')
        var menuList = document.querySelector('.menuList')
        var freeRed = document.querySelector('.renew')
        if (val == true) {
          leftMenu.style.top = '46px'
          header.style.top = '46px'
          content.style.marginTop = '46px'
          menuList.style.paddingBottom = '46px'
          if (freeRed) {
            content.style.marginTop = ''
            freeRed.style.marginTop = '48px'
          }
        } else {
          leftMenu.style.top = ''
          header.style.top = ''
          content.style.marginTop = ''
          menuList.style.paddingBottom = ''
        }
        // }
      } catch (e) {
        // console.log(e)
      }
    },
    $route(to, from) {
      if (to.path == '/') {
        if (Object.keys(this.headerMessageInfo).length != 0) {
          this.msg = true
        }
        // this.msg = true
      }
      if (to.path != '/' && from.path == '/') {
        this.msg = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
// .headerMessageDialogconten::-webkit-scrollbar {
//   width: 0px;
//   height: 0px;
// }
::v-deep .el-dialog__body {
  padding-top: 0;
}

.headerMessage {
  width: 100%;
  height: 46px;
  background: #1b9d97;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  .headerMessageBox {
    width: 80%;
    margin: 0 auto;
    position: relative;
    height: 13px;
    margin-top: 16px;
    padding-right: 51px;
  }

  img {
    position: absolute;
  }

  p {
    font-size: 13px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    max-width: 98%;
    margin-left: 20px;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 13px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffde3a;
    line-height: 13px;
    cursor: pointer;
  }

  .headerMessageDialog {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
  }
}

.dialog_font1 {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  line-height: 54px;
}

.dialog_font2 {
  margin-top: 10px;
  font-size: 12px;
  color: #999999;
  line-height: 12px;
}

.contenttt {
  font-size: 12px;
  color: #333333;
  line-height: 16px;
  overflow: auto;

  .dialogVisible_container {
    height: 408px;
  }

  ul {
    height: 308px;

    li {
      height: 20px;
      margin-top: 20px;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}
</style>

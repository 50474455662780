/*
 * @Author: cyy
 * @Date: 2022-01-11 10:58:38
 * @LastEditTime: 2022-01-13 11:38:57
 * @LastEditors: cyy
 * @Description: 免息券
 * @FilePath: \ teacher.dingdingkaike\src\router\module\marketingCenter\InterestFreeCoupon.js
 */

const router = {
    path: '/installment',
    name: 'installment',
    component: () => import('@view/MarketingCenter/installment/main'),
    meta: { requiresAuth: true, menu_id: 208 },
    children: [
        {
            path: 'newInstallment',
            name: 'newInstallment',
            component: () => import('@view/MarketingCenter/installment/newInstallment'),
            meta: { pageOption: { name: '创建' } }
        }, {
            path: 'detailInstallment',
            name: 'detailInstallment',
            component: () => import('@view/MarketingCenter/installment/detail/detailInstallment'),
            meta: { pageOption: { name: '详情' } }
        },

    ]
}

export default router
/*
 * @Descripttion:
 * @version:
 * @Author: sueRimn
 * @Date: 2019-11-19 10:11:50
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-12-09 08:35:01
 */
const router = {
  path: '/coursePrivate',
  component: () => import('@view/PrivateCourse/PrivateCourse'),
  meta: { requiresAuth: true, menu_id: 12 },
  // meta: { notLogin: true },
  children: [
    {
      path: '',
      component: () => import('@view/PrivateCourse/List'),
      meta: {
        requiresAuth: true
      }
      // meta: { notLogin: true }
    },
    {
      path: 'EditCourse',
      name: 'EditCourse2',
      component: () => import('@view/PrivateCourse/EditCourse'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '管理' },
        menu_id: 179
      },
      children: [
        {
          path: 'dataAnalysisDetail',
          name: 'dataAnalysisDetail2',
          component: () =>
            import('@/components/dataAnalysis/dataAnalysisDetail2'),
          meta: {
            requiresAuth: true,
            pageOption: {
              name: '统计详情',
              prompt:
                '数据统计包含系列课；当前课程如存在系列课中，系列课产生的数据也会被统计在当前课程'
            }
          },
          children: [
            {
              path: 'viewDetail/id/:id',
              component: () => import('@/components/dataAnalysis/viewDetail'),
              meta: {
                requiresAuth: true,
                pageOption: {
                  name: '详情'
                }
              }
              // meta: { notLogin: true }
            }
          ]
          // meta: { notLogin: true }
        }
      ]
      // meta: { notLogin: true }
    },
    {
      path: 'AddCourse',
      component: () => import('@view/PrivateCourse/AddCourse'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '创建' },
        menu_id: 177
      }
      // meta: { notLogin: true }
    }
  ]
}

export default router

/*
 * @Author: pengmingjun 1317525216@qq.com
 * @Date: 2022-05-11 15:04:21
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-09-16 10:55:18
 * @FilePath: \teacherdingding.com\src\router\module\questionnaire\courseWorkbook.js
 * @Description: 助学工具-作业
 * 父子路由不需要一起显示，不然父组件还得写<router-view></router-view> 然后父组件部分内容还要v-if
 * 现在子组件直接写成一级路由形式 相当于直接顶层router-view中显示 父组件中不用router-view了 父与子之间独立出来
 */

export const router2 = {
  path: '/courseWorkbook/studentWorkbook/:id',
  component: () => import('@view/courseWorkbook/components/studentWorkbook'),
  meta: {
    requiresAuth: true,
    pageOption: {
      name: '提交记录',
    },
  },
}

export const router3 = {
  path: '/courseWorkbook/studentWorkbook/:id/answer/:uid',
  component: () => import('@view/courseWorkbook/components/memberworkbook'),
  meta: {
    requiresAuth: true,
    pageOption: { name: '查看' }
  },
}

export const router = {
  path: '/courseWorkbook',
  name: 'courseWorkbook',
  component: () => import('@view/courseWorkbook/courseWorkbook'),
  meta: { requiresAuth: true, menu_id: 165 },
  children: [
    {
      path: 'newData/:id',
      name: 'newData',
      component: () => import('@view/courseWorkbook/components/newData'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '新建作业本' },
      },
    },
    {
      path: 'editor/:id?',
      name: 'editor',
      component: () => import('@view/courseWorkbook/components/editorData'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '管理' },
      },
      children: [
        {
          path: 'assignment/:id2/:type',
          name: 'assignment',
          component: () => import('@view/courseWorkbook/components/assignment'),
          meta: {
            requiresAuth: true,
            pageOption: { name: '布置作业' },
          },
        },
      ],
    },
  ],
}

/*
 * @Descripttion:
 * @version:
 * @Author: sueRimn
 * @Date: 2019-10-29 10:21:03
 * @LastEditors: cyy
 * @LastEditTime: 2022-10-19 15:02:19
 */
import Vue from 'vue'
import axios from '@/request/axios'

import { logOut } from '@ass/js/cookie'

// 添加全局的方法或者属性
const MyPlugin = {}

MyPlugin.install = function (Vue) {
  // 是否是PC微信
  Vue.prototype.isPcWx = /WindowsWechat/i.test(window.navigator.userAgent)

  Vue.prototype._isPhone = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
    navigator.userAgent
  )

  Vue.prototype.$http = function (options) {
    let xhr

    const promise = new Promise((resolve, reject) => {
      xhr = axios(
        options,
        ({ data }) => {
          // HTTP状态码 or 后台返回的状态码
          if (!data) {
            console.log(data)
            console.info(`url: ${options.url} not data`)
            reject()
            return
          }
          if (data.code === 302 || data.code === 404) {
            reject()
            logOut()
            return
          }
          if (!options.mute && data.info) {
            this.$root.prompt({
              msg: data.info,
              type: data.code == 200 ? 'success' : 'error',
              customClass: this._isPhone
                ? 'mobileMessage_2020_12_9_1_3_9_7'
                : '',
            })
          }
          if (Number(data.code) !== 200) {
            options.error && options.error(data)
            reject(data)
          } else {
            // 200 自动弹出提示
            if (options.successMsg) {
              this.$root.prompt({
                msg:
                  typeof options.successMsg === 'string'
                    ? options.successMsg
                    : '操作成功',
                type: 'success',
              })
            }
            if (options.callback) {
              options.callback(data)
            }
            resolve(data)
          }
          options.complete && options.complete(data)
        },
        e => {
          if (typeof e === 'object') {
            this.$root.prompt(
              options.errorMessage || '操作失败，请检查网络或重试'
            )
          }
          options.error && options.error()
          options.complete && options.complete()
          reject()
        }
      )
    })

    promise.abort = xhr.abort

    return promise
  }

  // 限制只能输入数字
  // isInt只能输入整数
  Vue.prototype.toNumber = function (value, isInt) {
    let newValue = value.match(/^[0-9]+(?:\.?\d{0,2})?/g)
    newValue = newValue ? newValue[0] : ''
    let formattedValue = newValue
    if (newValue) {
      formattedValue = newValue.slice(
        0,
        newValue.indexOf('.') === -1 ? 7 : newValue.indexOf('.') + 3
      )
    }
    return formattedValue && isInt
      ? Number.parseInt(formattedValue)
      : formattedValue
  }

  // 截取数字
  // max1 整数位
  // max2 小数位
  Vue.prototype.toNumber2 = function (value, max1 = 9, max2 = 2) {
    const exp = new RegExp(`^(\\d+)(\\.{0,${max2 ? 1 : 0}}\\d{0,${max2}})`)
    let newValue = value.match(exp)
    try {
      newValue = newValue[1].substring(0, max1) + newValue[2]
    } catch (e) {
      // console.error(e)
      newValue = ''
    }
    return newValue ? newValue : ''
  }

  // 原生跳转
  Vue.prototype.locationHref = function (url) {
    location.href = url
  }

  // 对象转换成get形式
  Vue.prototype.objToStr = function (obj) {
    let scene = ''
    for (let i in obj) {
      scene += (scene ? '&' : '?') + `${i}=${obj[i]}`
    }
    return scene
  }
}

Vue.use(MyPlugin)

// 时间戳转换
Vue.filter('formatTimeStamp', function (time, format) {
  if (!time || time == 0) {
    return ''
  }
  if (isNaN(time)) {
    return ''
  }
  format = format || 'yyyy-MM-dd hh:mm:ss'
  let date = new Date(time * 1000)
  let o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
  }
  if (/(y+)/.test(format) || /(Y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
    }
  }
  return format
})

// 课程类型文字
Vue.filter('courseTypeText', function (type, live_mode) {
  let text = ''
  switch (Number(type)) {
    case 1:
      text = live_mode
        ? live_mode == 2
          ? '直播课-极速模式'
          : '直播课-普通模式'
        : '直播课'
      break
    case 2:
      text = '小班课'
      break
    case 3:
      text = '录播课'
      break
    case 4:
      text = '系列课'
      break
    case 5:
      text = '公开课'
      break
    case 6:
      text = '图文'
      break
  }
  return text
})

// 版本类型文字
Vue.filter('versionText', function (type) {
  let text = ''
  switch (Number(type)) {
    case 1:
      text = '免费版'
      break
    case 2:
      text = '标准版'
      break
    case 3:
      text = '专业版'
      break
    case 4:
      text = '尊享版'
      break
  }
  return text
})

Vue.filter('ellipsis', function (value, len) {
  if (!value) return ''
  if (value.length > len) {
    return value.slice(0, len) + '...'
  }
  return value
})

// 课程类型文字
Vue.filter('formatTimeLength', function (times, type) {
  //type 1，时间到小时 2，时间到天
  if (!times) {
    if (type == 2) {
      return '00天00时00分00秒'
    } else {
      return '00:00:00'
    }
  }
  //当前的时间
  let seconds = times % 60
  let minutes = Math.floor(times / 60) % 60
  let hours = Math.floor(times / 60 / 60)
  if (type && (type == 2 || type == 3)) {
    hours = Math.floor(times / 60 / 60) % 24
  }
  let day = Math.floor(times / 60 / 60 / 24)

  if (type && type == 2) {
    return (
      (day < 10 ? '0' + day : day) +
      '天' +
      (hours < 10 ? '0' + hours : hours) +
      '时' +
      (minutes < 10 ? '0' + minutes : minutes) +
      '分' +
      (seconds < 10 ? '0' + seconds : seconds) +
      '秒'
    )
  } else if (type && type == 3) {
    return {
      day,
      hours,
      minutes,
      seconds,
    }
  } else {
    return (
      (hours < 10 ? '0' + hours : hours) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      (seconds < 10 ? '0' + seconds : seconds)
    )
  }
})

// 剩余时间转时分秒
Vue.filter('formatTimeLength2', function (times, format) {
  format = format || 'dd:hh:mm:ss'
  if (!times || times < 0) return '00:00:00'
  times = Math.floor(times)
  //当前的时间
  let seconds = times % 60
  let minutes = Math.floor(times / 60) % 60
  let hours = Math.floor(times / 60 / 60) % 24
  let date = Math.floor(times / 60 / 60 / 24)
  const o = {
    'd+': date < 10 ? '0' + date : date,
    'h+': hours < 10 ? '0' + hours : hours,
    'm+': minutes < 10 ? '0' + minutes : minutes,
    's+': seconds < 10 ? '0' + seconds : seconds,
  }

  for (let k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, o[k])
    }
  }

  return format
})

// 价格分割 例如 123,456,7.00
Vue.filter('priceSplit', function (price) {
  if (!price) return 0
  let newPrice = '' + Math.abs(price)
  const isSign = price < 0 ? '-' : ''
  const temp = newPrice.split('.')
  const len = temp[0].length
  if (len > 3) {
    let i = 2
    let arr = temp[0].split('').reverse()
    while (i < len) {
      arr[i] = `,${arr[i]}`
      if (!arr[i + 1]) break
      i += 3
    }
    newPrice =
      arr
        .reverse()
        .join('')
        .replace(/^,/, '') + (temp[1] ? `.${temp[1]}` : '')
  }
  return isSign + newPrice
})

// 去掉图片后缀
Vue.filter('imageslim', function (value, fullType) {
  let fullExt
  let result
  const regExp = new RegExp('\\?{1}\\w+(\\/?\\w*)*', 'i')

  switch (Number(fullType)) {
    case 1:
      fullExt = ''
      break
    case 2:
      fullExt = '?imageslim'
      break
    case 3:
      fullExt = '?imageView2/1/w/800/h/450'
      break
    case 4:
      fullExt = '?imageView2/1/w/400/h/225'
      break
    case 5:
      fullExt = '?imageView2/2/w/141'
      break
    default:
      fullExt = '?imageView2/1/w/160/h/90'
  }

  // 存在后缀直接替换（兼容旧数据）
  if (regExp.test(value)) {
    result = value.replace(regExp, fullExt)
  } else {
    // 否则 直接添加后缀
    result = `${value}${fullExt}`
  }
  return result
})

// 获取小数
Vue.filter('floatNumber', function (value, float = 2) {
  return `${value}`.replace(/\.(\d+)/, function (e, $1) {
    return `.${$1.substr(0, float)}`
  })
})

// 注册一个全局自定义指令 `v-focus`
Vue.directive('focus', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted(el) {
    // 聚焦元素
    el.focus()
  },
})

//注册全局动态动态指令-margin-top
//  <p v-pin:[direction]="200">I am pinned onto the page at 200px to the left.</p>
Vue.directive('pin', {
  bind: function (el, binding) {
    var s = binding.arg == 'left' ? 'left' : 'top'
    el.style[s] = binding.value + 'px'
  },
})

// 浮动框拖拽
Vue.directive('drag', {
  //1.指令绑定到元素上回立刻执行bind函数，只执行一次
  //2.每个函数中第一个参数永远是el，表示绑定指令的元素，el参数是原生js对象
  //3.通过el.focus()是无法获取焦点的，因为只有插入DOM后才生效
  bind: function (el) {
    el.style.cursor = "move"; //鼠标样式变move样式
  },
  //inserted表示一个元素，插入到DOM中会执行inserted函数，只触发一次
  inserted: function (element) {
    let el = element.parentElement.parentElement
    element.onmousedown = function (e) {
      var distX = e.pageX - el.offsetLeft;
      var distY = e.pageY - el.offsetTop;
      // console.log("元素本身的高：" + el.clientHeight + ",元素本身的宽：" + el.clientWidth)

      if (e.preventDefault) {
        e.preventDefault();
      } else {
        e.returnValue = false;
      };//解决快速拖动滞后问题

      document.onmousemove = function (e) {

        // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - distX;
        let top = e.clientY - distY;

        if (left <= 0) {
          left = 5; //设置成5是为了不离边缘太近
        } else if (left > document.documentElement.clientWidth - el.clientWidth) {
          //document.documentElement.clientWidth 屏幕的可视宽度
          left = document.documentElement.clientWidth - el.clientWidth - 5
        }
        if (top <= 0) {
          top = 5;
        } else if (top > document.documentElement.clientHeight - el.clientHeight) {
          top = document.documentElement.clientHeight - el.clientHeight - 5
        }
        el.style.left = left + 'px';
        el.style.top = top + 'px';
        el.style.right = 'auto';
      }
      document.onmouseup = function () {
        document.onmousemove = document.onmouseup = null;
      }
    }
  },
  //当VNode更新的时候会执行updated，可以触发多次
  updated: function () { }
})

Vue.directive('draggable', {
  inserted: (el) => {
    let disx = 0;
    let disy = 0;
    let time = null;
    let timeNow = null;

    el.onmousedown = (e) => {
      disx = e.pageX - el.offsetLeft;
      disy = e.pageY - el.offsetTop;
      time = Date.now();

      const onMouseMove = (e) => {
        timeNow = Date.now();
        if ((timeNow - time) < 100) return;

        // 获取窗口的视口大小  
        const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // 计算元素的新位置  
        let newLeft = e.pageX - disx;
        let newTop = e.pageY - disy;

        // 确保元素不会超出窗口边缘  
        if (newLeft < 0) {
          newLeft = 0;
        } else if (newLeft + el.offsetWidth > viewportWidth) {
          newLeft = viewportWidth - el.offsetWidth;
        }

        if (newTop < 0) {
          newTop = 0;
        } else if (newTop + el.offsetHeight > viewportHeight) {
          newTop = viewportHeight - el.offsetHeight;
        }

        // 设置元素的新位置  
        el.style.left = newLeft + 'px';
        el.style.top = newTop + 'px';
        el.style.right = 'auto';
        el.style.bottom = 'auto';
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      // 使用 document 而不是 el 来监听 mousemove 和 mouseup，确保即使在鼠标移动得很快时也能捕捉到事件  
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    };

    // 清除拖拽状态  
    el.ondragend = () => {
    };
  },
  unbind: (el) => {
    // 清理事件监听器  
    el.onmousedown = null;
  },
});
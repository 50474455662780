/**
 *  @description PC网校装修
 */

 const router = {
  path: '/pcSchoolDecoration',
  name: 'pcSchoolDecoration',
  component: () => import('@view/NetSchollAdmin/pcSchoolDecoration/pcSchoolDecoration'),
  meta: { requiresAuth: true, topPage: true }
}

export default router

/**
 *  @description 禁用PC微信
 */

const router = {
  path: '/pxWxDisable',
  name: 'pxWxDisable',
  component: () => import('@view/pxWxDisable/pxWxDisable'),
  meta: { requiresAuth: true }
}

export default router

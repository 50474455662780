/**
 *  @description 操作日志
 */

 const router = {
  path: '/log',
  name: 'log',
  component: () => import('@view/teachingAdmin/log'),
  meta: { requiresAuth: true, menu_id: 95 }
}

export default router

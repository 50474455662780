/**
 *  @description 聊天室页面
 */

const router = {
  path: '/charRoom',
  name: 'charRoom',
  component: () => import('@view/ChatRoom/index'),
  meta: { requiresAuth: true }
}

export default router

<!---24小时内的新机构会弹出这张图--->
<template>
  <transition name="fade">
    <div class="mask" v-if="advertising && showAdvertising">
      <div class="absoluteCenter advertising">
        <img class="img" src="~@ass/img/1.4.1.3/img_xrfl@2x.png" alt="" />
        <div class="close" @click="showAdvertising = false">
          <i class="el-icon-close" style="color: #fff"></i>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'Advertising',

  data() {
    return {
      showAdvertising: true,
    }
  },

  computed: {
    advertising() {
      return this.$store.state.userInfo.is_new_jigou
    },
  },

  watch: {},

  methods: {},
}
</script>
<style lang="scss" scoped>
.advertising {
  height: auto;
  width: 800px;
  .img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
  }
  .close {
    width: 26px;
    height: 26px;
    cursor: pointer;
    position: absolute;
    // background: url('~@ass/img/1.3.2/ico_gbtc.png');
    background-size: 100%;
    right: 5px;
    top: 10px;
  }
}
</style>

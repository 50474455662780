/*
 * @Author: cyy
 * @version:
 * @Date: 2022-07-12 14:33:15
 * @LastEditTime: 2022-07-18 17:38:55
 * @LastEditors: cyy
 * @Description:优惠券
 * @FilePath: \teacherdingding.com\src\router\module\marketingCenter\coupon.js
 */

const router = {
  path: '/coupon',
  name: 'coupon',
  component: () => import('@view/MarketingCenter/coupon/main'),
  meta: {
    requiresAuth: true,
    menu_id: 212,
  },
  children: [
    {
      path: 'newCoupon',
      name: 'newCoupon',
      component: () => import('@view/MarketingCenter/coupon/newCoupon'),
      meta: { pageOption: { name: '新建优惠券' } },
    },
    {
      path: 'dataeffect/:id',
      name: 'dataeffect',
      component: () => import('@view/MarketingCenter/coupon/dataeffect'),
      meta: { pageOption: { name: '数据效果' } },
      children: [
        {
          path: 'directional/:id/:type',
          name: 'directional',
          component: () => import('@view/MarketingCenter/coupon/directional'),
          meta: { pageOption: { name: '查看详情' } },
        },
      ],
    },

    {
      path: 'target/:id',
      name: 'target',
      component: () => import('@view/MarketingCenter/coupon/target'),
      meta: { pageOption: { name: '定向发放' } },
    },
  ],
}

export default router

/*
 * @Descripttion: 旧首页
 * @version: 1.1pcMenu
 * @Author: sueRimn
 * @Date: 2019-11-04 17:29:22
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-11-08 18:25:55
 */
/**
 *  @description 今日安排页面
 */


const router = {
  path: '/class',
  name: 'class',
  component: () => import('@view/Home/classSchedule'),
  // meta:{notLogin: true}
  meta: { requiresAuth: true, menu_id: 58 }
}

export default router


<template>
  <div class="error-box">
    <h2 class="error-code">403</h2>
    <div class="error-msg">抱歉，当前用户暂无权限，请联系管理员开通权限</div>
    <div class="error-msg">{{ time }}S 后自动跳转</div>
  </div>
</template>
<script>
import bg from '@/mixin/background'

export default {
  name: 'forbidden',

  mixins: [bg],

  data() {
    return {
      time: 3,
    }
  },

  mounted() {
    this.start()
  },

  beforeRouteUpdate(to, from, next) {
    this.destory()
    this.start()
    next()
  },

  destroyed() {
    this.destory()
  },

  methods: {
    destory() {
      this.time = 3
      clearTimeout(this.timer)
    },

    start() {
      this.timer = setInterval(() => {
        this.time--
        if (this.time == 0) {
          this.destory()
          if (this.$store.getters.homePath) {
            this.$router.replace(this.$store.getters.homePath)
          }
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.error-box {
  margin: 150px auto 300px;
  padding: 60px 20px;
  width: 90%;
  max-width: 900px;
  text-align: center;
  border: 1px solid #ddd;
  color: #999;
  background: #fff;
  .error-code {
    font-size: 40px;
  }
  .error-msg {
    font-size: 18px;
    margin-top: 10px;
  }
}
</style>

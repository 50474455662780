/*
 * @Descripttion: teacher.dingdingkaike
 * @version: 1.1pcMenu
 * @Author: sueRimn
 * @Date: 2019-11-04 17:29:22
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-11-12 20:08:23
 */
/**
 *  @description 课程管理页面
 */

const router = {
  path: '/courseAdmin',
  name: 'courseAdmin',
  component: () => import('@view/CourseAdmin/CourseAdmin'),
  meta: { requiresAuth: true }
}

export default router

/*
 * @Descripttion: 入口方法
 * @version: 1.1pcMenu
 * @Author: sueRimn
 * @Date: 2019-11-04 17:29:22
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-07-06 18:14:55
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import getEnv from '@/config/getEnv'

import '@ass/js/utils'
import '@ass/css/reset.scss'
import '@ass/css/normalize.css'
import ElementUI from 'element-ui'
// element 偶现图标乱码的情况
// node_modules\element-ui\packages\theme-chalk\src\base.scss 需要去注释@import "icon.scss";
import '@ass/css/element-variables.scss'
import 'element-ui/lib/theme-chalk/icon.css'
import '@/assets/css/common.scss'

// 全局注册组件
import iframe from '@/components/Iframe'
import pagination from '@cm/base/pagination'
import newPagination from '@cm/base/newPagination'
import loading from '@/components/LoadGif'
import helpIcon from '@/components/helpIcon'
import YSelect from '@/components/YSelect'

//这里只注册部分vant组件
import { Field, Cell, CellGroup, Divider, Picker, Popup,CountDown  } from 'vant'

Vue.use(Popup)
Vue.use(Picker)
Vue.use(Divider)
Vue.use(Field)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(CountDown)
//这里只注册部分vant组件 newschool页面有用到

Vue.component('my-iframe', iframe)
Vue.component('pagination', pagination)
Vue.component('pagination2', newPagination)
Vue.component('loading', loading)
Vue.component('helpIcon', helpIcon)
Vue.component('YSelect', YSelect)
Vue.component('Lazy', () => import('@/components/ImgLazyload'))
Vue.component('classesBegin', () => import('@cm/base/classesBegin'))
Vue.component('previewVideo', () => import('@cm/coursePop/previewVideo'))

//elemetui全局注册
Vue.use(ElementUI)

// 公交车
Vue.prototype.$bus = new Vue()

// Element-ui
Vue.config.productionTip = false

// 发送错误日志
if (getEnv() === 'prod') {
  Sentry.init({
    Vue,
    dsn:
      'https://745c4ea75bbe41cb86340ad6900cb7e1@o1147109.ingest.sentry.io/6216940',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
      }),
    ],
    ignoreErrors: [
      'Non-Error promise rejection captured',
      'ChunkLoadError',
      'NavigationDuplicated',
      '<unknown>',
      /Syntax\serror/i,
      /code\s502/i,
      /被邀请加入/i,
      /操作权限不足/i,
      /Redirected\swhen\sgoing\sfrom/i,
      /Network\sError/i,
      /Syntax\serror/i,
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
    logErrors: true,
  })
}

new Vue({
  router,
  store,
  render: h => h(App),
  methods: {
    // 全局提示的方法 this.$root.prompt
    prompt(msg, time) {
      this.$children[0].prompt(msg, time)
    },

    // 全局升级续费的方法 this.$root.toggleUpdatePopup
    toggleUpdatePopup(disable) {
      this.$children[0].toggleUpdatePopup(disable)
    },

    // 全局产品升级提示的方法 this.$root.toggleProductUpdate
    toggleProductUpdate() {
      this.$children[0].toggleProductUpdate()
    },

    toggleShowLimit() {
      this.$children[0].toggleShowLimit()
    },

    // reload
    reload() {
      this.$children[0].reload()
    },
  },
}).$mount('#app')

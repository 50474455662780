/*
 * @Author: cyy
 * @version: 
 * @Date: 2022-11-21 14:25:41
 * @LastEditTime: 2022-11-22 18:47:48
 * @LastEditors: cyy
 * @Description: 秒杀
 * @FilePath: \teacherdingding.com\src\router\module\marketingCenter\seckill.js
 */

export const router = {
    path: '/seckill',
    name: 'seckill',
    component: () => import('@view/MarketingCenter/seckill/main'),
    meta: { requiresAuth: true, menu_id: 217 },

}

export const router2 = {

    path: '/seckill/newSeckill',
    name: 'newSeckill',
    component: () => import('@view/MarketingCenter/seckill/newSeckill'),
    meta: {
        requiresAuth: true, pageOption: { name: '创建' }
    },
}
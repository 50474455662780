/*
 * @Descripttion: teacher.dingdingkaike
 * @version: 1.1pc
 * @Author: sueRimn
 * @Date: 2019-10-29 10:21:03
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-09-15 15:48:23
 */
import Vue from 'vue'
import Router from 'vue-router'
import empty404 from '@view/404'
import forbidden from '@view/forbidden'

// 全局登录拦截方法
import login from '../login'

Vue.use(Router)

const routes = [
  {
    path: '*',
    name: 'notFound',
    component: empty404,
    meta: { requiresAuth: true, pageOption: { name: 404 } },
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: forbidden,
    meta: { requiresAuth: true, pageOption: { name: '暂无权限' } },
  },
  {
    path: '/login',
    name: 'loginPage',
    component: () => import('@view/Login'),
    meta: { notLogin: true }, //登录后不可进入
  },
  {
    path: '/newschool',
    name: 'newschoolPage',
    component: () => import('@view/newschool'),
    meta: { requiresAuth: true }, //这个设置登录后可进入
  },
  {
    path: '/testingDevice',
    name: 'testingDevice',
    component: () => import('@cm/windowsApp/testingDevice'),
    meta: { notLogin: true, topPage: true }, //这个设置登录后可进入
  },
  {
    path: '/liveShare',
    name: 'liveShare',
    component: () => import('@cm/windowsApp/liveShare'),
    meta: { notLogin: true, topPage: true }, //这个设置登录后可进入
  },
  {
    path: '/protocol',
    name: 'protocolPage',
    component: () => import('@view/protocol'),
    meta: { notLogin: true },
  },
  {
    path: '/switchSchool',
    name: 'switchSchool',
    component: () => import('@view/switchSchool'),
    meta: { requiresAuth: true, topPage: true },
  },
  {
    path: '/organizationDisable',
    name: 'organizationDisable',
    component: () => import('@view/organizationDisable'),
    meta: { requiresAuth: true, topPage: true },
  },
]

// 获取module文件夹下面的路由数据
// 第一个参数 目录名称
// 第二个参数 是否读取子目录
// 第三个参数 正则匹配文件类型
const files = require.context('./module', true, /\.js$/)
/**
 * 重写路由的push方法
 */
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }

// 循环添加module页面下面的路由数据
files.keys().forEach(key => {
  routes.push(...Object.values(files(key)))
})
// 注册路由
export default login(
  new Router({
    routes,

    // 滚动行为
    scrollBehavior() {
      // to, from, savedPosition
      // if (savedPosition) {
      //   return savedPosition;
      // } else {
      //   return { x: 0, y: 0 };
      // }
      return { x: 0, y: 0 }
    },
  })
)


  /**
 *  @description 电子资料
 */

const router = {
    path: '/courseElectronics',
    name: 'courseElectronics',
    component: () => import('@view/dataPage/dataPage'),
    meta: { requiresAuth: true, menu_id: 87 },
    children: [
      {
        path: 'newData/:id',
        name: 'newData',
        component: () => import('@view/dataPage/components/newData'),
        meta: {
          requiresAuth: true,
          pageOption: { name: '新建电子资料' }
        }
      },
      {
        path: 'editor/:id',
        name: 'editor',
        component: () => import('@view/dataPage/components/editorData'),
        meta: {
          requiresAuth: true,
          pageOption: { name: '管理' },
          menu_id: 197
        }
      }
    ]
  }
  
  export default router
  
/**
 *  @description 助学工具-调查表
 */

const router = {
  path: '/tool/naire',
  name: 'tool',
  component: () => import('@view/Survey/displaySurveyList'),
  meta: { requiresAuth: true, menu_id: 29 },
  children: [
    {
      path: 'completedCadets',
      name: 'completedCadets',
      component: () => import('@view/Survey/completedCadets'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '详情' }
      }
    },
    {
      path: 'newlyBuild',
      name: 'newlyBuild',
      component: () => import('@view/Survey/newlyBuild'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '新建' }
      }
    },
    {
      path: 'newlyBuild1',
      name: 'newlyBuild1',
      component: () => import('@view/Survey/newlyBuild'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '编辑' }
      }
    }
  ]
}

export default router

/**
 *  @description 助教页面
 */

const router = {
  path: '/manageAssistant',
  name: 'manageAssistant',
  component: () => import('@view/teachingAdmin/assistant'),
  meta: { requiresAuth: true, menu_id: 60 }
}

export default router

/*
 * @Author: cyy
 * @version: 
 * @Date: 2022-06-27 15:38:45
 * @LastEditTime: 2022-09-13 18:39:02
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\router\module\marketingCenter\redeemCodew.js
 */
/**
 *  @description 兑换码  
 */

const router = {
  path: '/marketingRedeem',
  name: 'marketingRedeem',
  component: () => import('@view/MarketingCenter/redeemCode'),
  meta: { requiresAuth: true, menu_id: 153 },
  children: [
    {
      path: 'addRedeemCode',
      name: 'addRedeemCode',
      component: () => import('@view/MarketingCenter/addRedeemCode'),
      meta: { pageOption: { name: '新建兑换码' } }
    },
    {
      path: 'editRedeemCode',
      name: 'editRedeemCode',
      component: () => import('@view/MarketingCenter/editRedeemCode'),
      meta: { pageOption: { name: '兑换码详情', prompt: '提示：兑换码的内容、分享、作废仅支持创建人操作', iconPrompt: 2 } }
    },

  ]
}

export default router

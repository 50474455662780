/**
 *  @description 营销中心页面
 */

const router = {
  path: '/marketingCenter',
  name: 'marketingCenter',
  component: () => import('@view/MarketingCenter/MarketingCenter'),
  meta: { requiresAuth: true }
}

export default router

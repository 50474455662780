<!--
 * @Descripttion: 弹窗
 * @version: 1.1pcMenu
 * @Author: ivan
 * @Date: 2019-11-06 11:47:08
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-11-10 22:24:57
 -->
<template>
  <fade-animation>
    <div class="tishi-box">
      <h3>{{ title }}</h3>
      <p>{{ content }}</p>
      <div>
        <button class="account-login-btn" @click.stop="submit">
          {{ btnTxt }}
        </button>
      </div>
    </div>
  </fade-animation>
</template>
<script>
import fadeAnimation from '@/components/FadeAnimation'
export default {
  props: {
    title: {
      type: String,
      default: '温馨提示'
    },
    content: {
      type: String,
      default: '该模块将在后续产品升级中开放，感谢您的支持!'
    },
    btnTxt: {
      type: String,
      default: '确定'
    }
  },
  components: {
    fadeAnimation
  },
  methods: {
    submit() {
      this.$emit('makeSubmit')
    }
  }
}
</script>
<style lang="scss" scoped>
.tishi-box {
  position: fixed;
  width: 480px;
  left: 50%;
  top: 50%;
  padding: 20px 30px;
  transform: translate(-50%, -50%);
  background: #fff;
  box-sizing: border-box;
  h3 {
    font-size: 18px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 24px;
  }
  p {
    padding: 40px 0 87px;
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
    line-height: 21px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      font-size: 14px;
      color: rgba(27, 157, 151, 1);
      line-height: 19px;
      cursor: pointer;
    }
    button {
      width: 68px;
    }
  }
}
.account-login-btn {
  cursor: pointer;
  border: 0;
  color: #fff;
  width: 100%;
  height: 36px;
  display: block;
  font-size: 13px;
  font-weight: bold;
  line-height: 17px;
  background: #0aa29b;
  border-radius: 2px;
}
</style>

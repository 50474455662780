<template>
  <transition name="fade">
    <div class="mask" @click.self="parentEvent">
      <slot></slot>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'componentFade',

  methods: {
    parentEvent() {
      this.$emit('close')
      this.$emit('parentEvent')
    }
  }
}
</script>
<style>
.fadeConfirmChild {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

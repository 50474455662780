<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-05-11 17:34:13
 * @LastEditTime: 2022-12-02 16:28:40
 * @LastEditors: cyy
 * @Description: 分页组件
 * @FilePath: \teacherdingding.com\src\components\base\newPagination.vue
-->
<template>
  <div class="newPagination">
    <slot
      name="other"
      :tableData="tableData"
      :newDate="newDate"
      :total="total"
    ></slot>
    <slot :tableData="tableData" :empty="empty" :total="total"></slot>
    <slot name="footer" :tableData="tableData" :total="total"></slot>
    <div class="contain_page">
      <div
        class="pagination"
        v-if="(allFn ? total > pageSize[0] : total > post.num) && hadPagination"
      >
        <el-pagination
          :pager-count="pagerCount"
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="currPage"
          :page-sizes="pageSize"
          :page-size="post.num"
          :layout="
            `${
              allFn && !$store.state.isWindowsAppV2
                ? 'total, sizes, prev, pager, next, jumper'
                : 'prev, pager, next'
            }`
          "
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <slot name="bottom" :tableData="tableData" :total="total"></slot>
    <loading v-show="isNoData" leftPos="0"></loading>
  </div>
</template>
<script>
import loading from '@/components/LoadGif'
export default {
  name: 'pagination',
  components: {
    loading,
  },
  props: {
    // 是否需要分页
    hadPagination: {
      type: Boolean,
      default: true,
    },
    // 总条数
    allFn: {
      type: Boolean,
      default: true,
    },

    // 是否显示lodding
    lodding: {
      type: Boolean,
      default: true,
    },

    pagerCount: {
      type: Number,
      default: 5,
    },

    url: String,
    option: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      newDate: {},

      isNoData: false,

      currPage: 1,

      // 是否没有数据
      empty: false,

      tableData: [],

      post: Object.assign({}, this.option, {
        page: 1,
        num: 0,
        pagesize: 0,
        rid: '',
      }),

      total: 0,

      pageSizeName: 'pageSize',
    }
  },

  created() {
    //  this.option.pageSize ||
    //     localStorage.getItem(this.pageSizeName) ||
    const pagesize = Number(this.$store.state.pageSize[0])
    this.post = _.assign({}, this.post, {
      num: pagesize,
      pagesize: pagesize,
    },this.option)
  },

  computed: {
    pageSize() {
      return this.$store.state.pageSize
    },
  },

  watch: {
    post: {
      handler(newValue, oldValue) {
        const options = _.assign({}, this.post)
        // 如果page相等说明 变动的是其他数据 page 需重置
        if (
          newValue &&
          oldValue &&
          oldValue.page === newValue.page &&
          newValue.page !== 1
        ) {
          this.post.page = 1
          this.currPage = 1
        } else {
          this.getData(options)
        }
      },
      immediate: true,
      deep: true,
    },

    option: {
      handler(value) {
        this.post = Object.assign({}, this.post, value, {
          page: 1,
        })
        this.currPage = 1
      },
      deep: true,
    },
  },

  methods: {
    sizeChange(value) {
      // localStorage.setItem(this.pageSizeName, value)
      this.post = _.assign({}, this.post, {
        num: value,
        pagesize: value,
      })
    },

    //重置分页数据
    reset() {
      this.post.rid = Math.random()
    },

    currentChange(value) {
      this.currPage = value
      this.post = _.assign({}, this.post, { page: value })
    },

    getData(data) {
      if (this.lodding) {
        this.isNoData = true
      }
      this.$http({
        url: this.url,
        data,
        isMultipleSelf: 'replacePrev',
        callback: ({
          data = [],
          count,
          all_number,
          total,
          allNum,
          num_new,
        }) => {
          this.total = Number(
            count ||
              all_number ||
              num_new ||
              total ||
              allNum ||
              data.all_number ||
              data.count ||
              data.total ||
              0
          )
          this.tableData = data || []
          this.$emit('tableDate', data || [])
        },
        error: () => {
          this.total = 0
          this.tableData = []
        },
        complete: data => {
          this.isNoData = false
          const newDate = _.assign({}, data || {}, {
            total: this.total,
            page: this.post.page,
          })
          this.newDate = newDate
          this.$emit('complete', newDate)
          this.empty = this.post.page == 1 && this.total == 0
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.pagination {
  padding: 10px;
  text-align: center;
  height: 36px;
  line-height: 36px;
  margin: 0 auto;
}
::v-deep .el-pagination {
  transform: scale(0.85);
  transform-origin: center;
}
::v-deep .el-pager li {
  background: #f4f4f5;
  margin: 0 4px;
  padding: 0;
  font-size: 12px;
}
::v-deep .el-pagination button {
  background: #f4f4f5 !important;
}
</style>

<template>
    <div class="AiIcon cp" v-draggable @mousedown="startDrag" @mouseup="endDrag">
        <div class="Box">
            <div class="lianyi1 animate"></div>
            <div class="lianyi2 animate"></div>
            <img class="icon" src="~@ass/img/1.4.47/icon_fire_cat.png" alt="" @click="showAi38k" draggable="false"  v-if="draggable"/>
            <el-tooltip effect="dark" content="火眼猫AI" placement="bottom" v-else>
                <img class="icon" src="~@ass/img/1.4.47/icon_fire_cat.png" alt="" @click="showAi38k" draggable="false"/>
            </el-tooltip>

        </div>
    </div>
</template>

<script>
export default {
    name: 'AiIcon',
    data: () => ({
        draggable: false,
        time: null,
        isMoveing:false,
    }),
    mounted() {},
    methods: {
        startDrag() {
            // console.log("startDrag", this.draggable);
            const onMouseMove = ()=>{
                this.draggable = true
            }
            const onMouseUp = ()=>{
                document.removeEventListener('mousemove', onMouseMove);
                clearTimeout(this.time)
                this.time = setTimeout(() => {
                    this.draggable = false
                }, 100);
            }
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        },
        endDrag() {
            // console.log("endDrag", this.draggable);
        },
        showAi38k() {
            if (this.draggable) return
            this.$emit("showAi38k")
        },
    },
}
</script>

<style lang="scss" scoped>
@mixin radio {
    border-radius: 50%;
}

@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.AiIcon {
    position: fixed;
    right: 0px;
    bottom: 120px;
    z-index: 99;

    .Box {
        position: relative;
        height: 100px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        .lianyi1 {
            @include radio;
            height: 70px;
            width: 70px;
            background: rgba(255, 255, 100, 0.2);
            position: absolute;
        }

        .lianyi2 {
            @include radio;
            height: 55px;
            width: 55px;
            background: rgba(255, 150, 0, 0.2);
            position: absolute;
        }

        .icon {
            width: 70px;
            position: absolute;
        }

        .animate {
            animation: scaleAnimation 3s ease-in-out infinite;
        }
    }
}
</style>
/*
 * @Descripttion: 路由守卫
 * @version:
 * @Author: 彭明军
 * @Date: (2019-09-29 17：29)
 * @LastEditors  : sueRimn
 * @LastEditTime : 2019-12-20 15:53:43
 */

// NProgress 顶部进度组件
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// showSpinner 隐藏loading显示
NProgress.configure({ showSpinner: false })

import { getCookie, resetCookie, setCookie } from '@ass/js/cookie'
import store from './store/store'

export default function(router) {
  /**
   * @desc 路由前置拦截
    /**
     *   线上环境
     */
  router.beforeEach((to, from, next) => {
    // 是否登录
    const isLogin = store.state.userInfo.isLogin

    NProgress.start()

    // 如果是PC微信 就不能用
    if (/WindowsWechat/i.test(window.navigator.userAgent)) {
      if (to.path === '/pxWxDisable') {
        next()
      } else {
        next({
          path: '/pxWxDisable',
        })
      }
    } else if (
      to.matched.some(record => record.meta.requiresAuth) &&
      !isLogin &&
      !getCookie('userKey')
    ) {
      // 全局检查登录态 判断是否登录
      const cbk = () => {
        if (to.path === '/login') {
          next()
        } else {
          resetCookie()
          store.commit('setUserInfo')
          next({
            path: `/login${store.state.jid ? `?jid=${store.state.jid}` : ''}`,
          })
        }
      }
      if (store.state.jid) {
        store.dispatch('getUserInfo', '/WxCustom/jigouCustomInfo').then(cbk)
      } else {
        cbk()
      }
    } else if (to.matched.some(record => record.meta.notLogin)) {
      // 如果是不需要登录的页面
      if (store.state.jid) {
        store
          .dispatch('getUserInfo', '/WxCustom/jigouCustomInfo')
          .then(function() {
            store.commit('setUserInfoRequest', null)
            next()
          })
      } else {
        next()
      }
    } else {
      // 进入页面之前的初始化操作判断
      const nextCallback = function() {
        // 判断机构是否已经创建网校，有是1，没有是2
        if (
          to.path !== '/newschool' &&
          to.path !== '/switchSchool' &&
          (!store.state.userInfo.umobile ||
            store.state.userInfo.newschool == 1 ||
            store.state.userInfo.is_remove == 1)
        ) {
          next({ path: '/newschool' })
          return
        } else if (to.name == 'newschoolPage') {
          next()
          return
        }
        // 机构数据清理和网校是否已被停用
        if (
          to.name != 'organizationDisable' &&
          to.name != 'switchSchool' &&
          Number(store.state.userInfo.status_type) != 1
        ) {
          next({ name: 'organizationDisable' })
          return
        } else if (
          to.name == 'organizationDisable' &&
          Number(store.state.userInfo.status_type) == 1
        ) {
          next({ path: '/' })
        }

        // 判断进入的页面有没有权限
        if (
          to.name != 'forbidden' &&
          to.name != 'notFound' &&
          to.matched.some(
            record =>
              'menu_id' in record.meta &&
              !store.state.roots.includes(record.meta.menu_id) &&
              !record.meta.notLogin
          )
        ) {
          next(
            to.name == 'schoolntro'
              ? store.getters.homePath
              : { name: 'forbidden' }
          )
          return
        }

        // 防止刷新 或者 手动输入路由 导致数据无变化
        store.commit('setRouterPage', {
          path: to.path,
          fullPath: to.fullPath,
          allMenuList: store.getters.allMenuList,
        })

        if (to.name == 'schoolntro' && store.state.isWindowsAppV2) {
          // 防止用户手动输入 就必须判断如果登录了 就跳首页去
          next({
            path: store.getters.homePath,
          })
        } else {
          next()
        }
      }

      if (process.env.NODE_ENV !== 'production') {
        store.dispatch('setLocalSessionAndUserKey').then(() => {
          setCookie('userKey', '123456')
        })
      }

      // 保证getUserInfo接口必须成功获取
      if (isLogin) {
        // 对比userInfo
        store.dispatch('isEqualUserInfo')
        nextCallback()
      } else {
        store.dispatch('getUserInfo').then(function() {
          store.commit('setUserInfoRequest', null)
          nextCallback()
        })
      }
    }
  })

  /**
   * @desc 路由后置拦截
   */
  router.afterEach(() => {
    // 隐藏顶部进度条
    setTimeout(() => {
      NProgress.done()
    }, 50)
  })

  return router
}

import axios from '@/request/axios'
const state = {
  // 选择课程分类的数据
  selectcourseType3statuslist: [],
  // 课程分类弹窗状态
  selectcourseType3status: false,
  //已经选择的老师的id
  teacherids: [],
  //老师弹窗选择的内容
  selectedteacherlist: [],
  //被选中图标的数据
  iconslist: {},
  //选中的课程分类的内容
  typeselectval: {},
  //指定网校内容选中的值
  singleselectcontentval: {},
  //被选中模块的数据
  selectdata: {},
  //本地上传
  uploadStart: false,
  //被选中的分组id
  group_id1: 0,
  //被选中的组件
  selected: '',
  //添加老师
  teacherSelectstatus: false,
  // 轮播图更换图片弹窗控制
  changgePicturestatus: false,
  //课程分类
  kechengtypestatus: false,
  //自定网校内容
  selectChangestatus: false,
  //图标导航
  selecticonstatus: false,
  //中间数据
  modules: [],
  //被选中图片的回调
  isSelectPicture: {},
  // 原始数据
  originModules: []
}
const getters = {}
const mutations = {
  // 选择课程分类数据
  selectcourseType3statuslist(state, val) {
    state.selectcourseType3statuslist = val
  },
  //更改老师选择弹窗回调的内容
  selectedteacherChange(state, value) {
    state.selectedteacherlist = value
  },
  //更改课程分类弹窗状态
  selectcourseType3statusChange(state, val) {
    state.selectcourseType3status = val
  },
  //更改选中的图标列表的数据
  iconSelected(state, value) {
    state.iconslist = value
  },
  //改变选中分类的值
  typeselectlist(state, value) {
    state.typeselectval = value
  },
  //更新选中的指定网校的值
  singleselectcontentvalchange(state, value) {
    // console.log(value)
    state.singleselectcontentval = value

  },
  //更改本地上传的弹窗的状态
  uploadStartChange(state, value) {
    state.uploadStart = value
  },

  //更改获取图片上传中的分组id
  groupid1Change(state, value) {
    state.group_id1 = value
  },

  //更新选中模块的索引和类型
  selectedChange(state, id) {
    if (id) {
      const val = state.modules.find(item => item.id == id)
      if (val) {
        state.selectdata = val
        state.selected = id
      }
    } else {
      state.selected = ''
      state.selectdata = {}
    }
  },

  //改变selectdata数据
  selectdataChange(state, value) {
    const val = _.merge({}, value)
    const index = state.modules.findIndex(item => item.id == value.id)
    state.modules.splice(index, 1, val)
  },
  //添加老师
  teacherSelect(state, { isopen, teacherids }) {
    state.teacherSelectstatus = isopen
    state.teacherids = teacherids
    // console.log(state.teacherids)
  },

  //网校装修-轮播图-更换图片
  changgePicture(state, value) {
    state.changgePicturestatus = value
  },

  //课程分类
  kechengtype(state, value) {
    state.kechengtypestatus = value
  },

  //自定网校内容
  selectChange(state, value) {
    state.selectChangestatus = value
  },

  //图标导航-选择图标
  selecticon(state, value) {
    state.selecticonstatus = value
  },

  // 添加新模块
  addModule(state, value) {
    let index
    if (state.modules.length > 0) {
      if (state.selected && state.selectdata.data_type != 9) {
        index = state.modules.findIndex(item => item.id == state.selected) + 1
      } else {
        index = state.modules.length - 1
      }
      state.modules.splice(index, 0, value)
    }
  },

  // 移动模块
  move(state, { oldIndex, newIndex }) {
    const oldItem = state.modules[newIndex]
    state.modules.splice(newIndex, 1, state.modules[oldIndex])
    state.modules.splice(oldIndex, 1, oldItem)
  },

  // 切换显示模块
  toggleShow(state, { index, status }) {
    state.modules.splice(
      index,
      1,
      _.assign({}, state.modules[index], { is_show: status })
    )
  },

  // 切换显示模块
  remove(state, index) {
    state.modules.splice(index, 1)
  },

  //改变选中图片的内容
  isSelectPicturechange(state, val) {
    state.isSelectPicture = val
  },
}

const actions = {
  networkSchoolDecration({ state }, val) {
    const request = axios(
      {
        name: 'networkSchoolDecration',
        url: '/home/index',
        data: {
          t_home_id: val
        }
      },
      ({ data: { code, bottom, data } }) => {
        if (code == 200) {
          state.modules = _.concat([], data, [
            { list: bottom, data_type: 9 }
          ]).map(item => {
            return _.assign({}, item, { id: Math.random() })
          })

          state.originModules = _.merge([], state.modules)

        }
      },
      () => {
        console.log('出错了')
      }
    ).request
    // 进行获取请求初次赋值 防止出现多次请求
    // commit('setUserInfoRequest', request)
    return request
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

/**
 *  @description 模板消息
 */

 const router = {
  path: '/setting/template',
  name: 'schoolTemplate',
  component: () => import('@view/schoolSetting/index'),
  meta: { requiresAuth: true, menu_id: 50 }
}
export default router

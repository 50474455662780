/*
 * @Descripttion:课程分类
 * @version:1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-19 10:11:50
 * @LastEditors: cyy
 * @LastEditTime: 2022-06-14 17:16:24
 */
const router = {
  path: '/liveGrouping',
  name: 'liveGrouping',
  component: () => import('@view/LiveGrouping/liveGrouping'),
  meta: { requiresAuth: true, menu_id: 218, topath: true },
  children: [
    {
      path: 'nextRank',
      name: 'nextRank',
      component: () => import('@view/LiveGrouping/nextRank'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '内容管理', topath: true },
      },
    },
  ],
}

export default router

// 获取文件长宽高，码率
export const getFileInfo = (file) => {
    return new Promise((resolve, reject) => {
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';

        videoElement.onloadedmetadata = () => {
            const duration = videoElement.duration;
            const width = videoElement.videoWidth;
            const height = videoElement.videoHeight;
            const size = file.size;
            const format = file.type;

            // 计算比特率，转换为kbps（千比特每秒）  
            const bitrate = (size * 8) / duration;
            const averageBitrate = (bitrate / 1024).toFixed(2);

            const videoMetadata = {
                width,
                height,
                duration: duration.toFixed(2),
                format,
                size,
                averageBitrate,
            };

            URL.revokeObjectURL(videoElement.src);
            resolve(videoMetadata);
        };

        videoElement.onerror = (error) => {
            console.error("无法加载视频元数据：", error);
            URL.revokeObjectURL(videoElement.src);
            reject(error);
        };

        videoElement.src = URL.createObjectURL(file);
    });
};

export const splitFileAndGetInfo = async (file, chunkSize = 1024 * 1024 * 1024) => { // chunkSize in bytes, 1GB = 1024 * 1024 * 1024  
    try {
        const chunks = [];
        let start = 0;
        while (start < file.size) {
            const end = Math.min(file.size, start + chunkSize);
            const chunk = file.slice(start, end);
            chunks.push(chunk);
            start = end;
        }

        const videoMetadataPromises = chunks.map(chunk => {
            return new Promise((resolve, reject) => {
                const videoElement = document.createElement('video');
                videoElement.preload = 'metadata';

                videoElement.onloadedmetadata = () => {
                    const duration = videoElement.duration;
                    const size = chunk.size; // 注意：这里的大小是片段的大小，不是整个文件的大小  
                    const format = chunk.type; // 片段可能没有有效的MIME类型，因为它们不是完整的文件 

                    const width = videoElement.videoWidth;
                    const height = videoElement.videoHeight;
                    // 计算比特率，转换为kbps（千比特每秒）  
                    const bitrate = (size * 8) / duration;
                    const averageBitrate = (bitrate / 1024).toFixed(2);
                    // 由于我们只有一个片段，所以无法准确计算整个文件的比特率  
                    // 你可能需要根据所有片段的总大小和估计的总时长来计算平均比特率  
                    const videoMetadata = {
                        duration: duration ? duration.toFixed(2) : null, // 可能为null，如果片段太小无法加载元数据  
                        size,
                        format,
                        // 其他与片段相关的元数据...  
                        width,
                        height,
                        averageBitrate,
                    };

                    URL.revokeObjectURL(videoElement.src);
                    resolve(videoMetadata);
                };

                videoElement.onerror = (error) => {
                    console.error("无法加载视频片段元数据：", error);
                    URL.revokeObjectURL(videoElement.src);
                    reject(error);
                };

                videoElement.src = URL.createObjectURL(chunk);
            });
        });

        // 等待所有片段的元数据加载完成  
        const videoMetadata = await Promise.all(videoMetadataPromises);
        return videoMetadata;
    } catch (error) {
        return []
    }
};

// 提现状态
export const payoutOptions = [
    {
        value: 0,
        label: '全部',
    },
    {
        value: 1,
        label: '不可提现',
    },
    {
        value: 2,
        label: '可提现',
    },
    {
        value: 3,
        label: '其它',
    },
]

export const sensitiveWordsShield = (value = '', keyworks = []) => {
    const sensitiveWords = keyworks
    // 存储每个敏感词的位置信息  
    const positions = sensitiveWords.map(word => {
        const text = value
        const regex = new RegExp(word, 'g');
        const wordLen = `${word}`.length //匹配词长度
        const matches = [];
        let match;

        while ((match = regex.exec(text)) !== null) {
            const startIndex = match.index;
            const endIndex = startIndex + wordLen;
            matches.push({ isReplace: true, position: [startIndex, endIndex] });
        }
        return matches
    });
    // console.log(positions);

    // 合并位置区间  
    const mergedPositions = mergePositions(positions);
    // console.log('mergedPositions', mergedPositions);
    const mergedArrays = mergedArray(mergedPositions);
    // console.log('mergedArray', mergedArrays);
    const replaceInterval = replaceIntervals(mergedArrays, value.length)
    const result = replaceInterval.map(item => {
        let str = ''
        const text = value
        let sliceText = text.slice(item.position[0], item.position[1])
        if (item.isReplace) {
            str = replactText(sliceText)
        } else {
            str = sliceText
        }
        return str
    }).join("")
    console.log(result);
    return result
}
// 合并位置区间  
export const mergePositions = (positions = []) => {
    const merge = positions.map(item => {
        let list = []
        item.forEach(curr => {
            let last = list[list.length - 1]
            if (!list.length) {
                list.push(curr)
            } else if (last.position[1] >= curr.position[0]) {
                list[list.length - 1].position[1] = Math.max(last.position[1], curr.position[1])
            } else {
                list.push(curr)
            }
        });
        return list
    })
    return merge;
}

// 合并具有交集的position
export const mergedArray = (arrays) => {
    // 合并position的逻辑  
    function mergeRanges(ranges) {
        ranges.sort((a, b) => a.position[0] - b.position[0]); // 按起始位置排序  
        let merged = [];
        let current = ranges[0];
        for (let i = 1; i < ranges.length; i++) {
            let next = ranges[i];
            if (current.position[1] >= next.position[0]) {
                // 有交集，合并区间  
                current.position[1] = Math.max(current.position[1], next.position[1]);
            } else {
                // 无交集，将当前区间加入merged，并更新current为下一个区间  
                merged.push(current);
                current = next;
            }
        }
        // 将最后一个区间加入merged  
        merged.push(current);
        return merged;
    }

    // 展开数组并合并position  
    let flattened = arrays.reduce((acc, arr) => acc.concat(arr), []);
    let mergedPositions = mergeRanges(flattened);

    return mergedPositions;
}

// 补齐没有替换的位置
export const replaceIntervals = (replaceIntervals = [], stringLength = 0) => {
    // 将 isReplace 为 false 的区间插入到正确位置  
    const allIntervals = [];

    // 添加字符串开始到第一个替换区间之前的区间（如果有的话）  
    if (replaceIntervals[0].position[0] > 0) {
        allIntervals.push({
            "isReplace": false,
            "position": [0, replaceIntervals[0].position[0]]
        });
    }

    // 遍历替换区间，并在它们之间插入 isReplace 为 false 的区间  
    for (const interval of replaceIntervals) {
        allIntervals.push(interval);
        if (interval.position[1] < stringLength) {
            // 添加当前替换区间结束到下一个替换区间开始之间的区间（如果有的话）  
            const nextIntervalStart = (replaceIntervals[replaceIntervals.indexOf(interval) + 1] || { position: [stringLength, stringLength] }).position[0];
            allIntervals.push({
                "isReplace": false,
                "position": [interval.position[1], nextIntervalStart]
            });
        }
    }

    // 添加最后一个替换区间结束到字符串末尾的区间（如果有的话）  
    if (replaceIntervals.length > 0 && replaceIntervals[replaceIntervals.length - 1].position[1] < stringLength) {
        allIntervals.push({
            "isReplace": false,
            "position": [replaceIntervals[replaceIntervals.length - 1].position[1], stringLength]
        });
    }

    // 输出所有区间  
    // console.log(allIntervals);
    return allIntervals
}

// 替换文本
export const replactText = text => {
    return `<i style="color: #ff3535;text-decoration: line-through;">${text}</i>`
}
// 示例使用
// const value = '这是一段包含敏感词的文本，敏感词敏感词需要被替换。';
// const sensitiveWords = ['敏感词'];
// const shieldedText = sensitiveWordsShield(value, sensitiveWords);
// console.log(shieldedText);
// 
export const getDom = (vm, name) => {
    return vm.$refs[name] ? vm.$refs[name][0] : null;
}
export const getDoms = (vm, name) => {
    return vm.$refs[name] ? vm.$refs[name] : null;
}

export const openAiWindow = () => {
    // 获取屏幕宽度和高度  
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    // 计算新窗口的左边距和上边距，这里假设我们想要将窗口居中显示  
    const left = (screenWidth - 400); // 宽度是400  
    const top = (screenHeight - 800); // 高度是800  
    const newWindow = window.open('', '_blank', `width=400,height=800,left=${left},top=${top}`);
    if (newWindow) {
        // 可以在这里为新窗口设置内容，例如写入 HTML 或重定向到某个 URL  
        //   newWindow.document.write('<h1>这是新窗口的内容</h1>');  
        // 或者重定向到另一个页面  
        newWindow.location.href = 'https://ai.38k.cn/';
        return newWindow
    } else {
        alert('浏览器阻止了弹出窗口。');
    }
}

// 替换字符串
export const maskString = (originalStr, frontKeep=0, endKeep=0, replaceChar = '*') => {
    if(!originalStr) return
    // 验证输入是否合法
    if (typeof originalStr !== 'string' || typeof frontKeep !== 'number' || typeof endKeep !== 'number') {
      // throw new Error('Invalid input types. Ensure originalStr is a string and frontKeep/endKeep are numbers.');
    }
    if (frontKeep < 0 || endKeep < 0) {
      throw new Error('frontKeep and endKeep must be non-negative.');
    }
    
    // 计算字符串总长度
    const strLength = originalStr.length;
    
    // 确保前后保留的位数之和不超过字符串长度
    if (frontKeep + endKeep > strLength) {
    //   throw new Error('The sum of frontKeep and endKeep exceeds the length of the string.');
      return originalStr
    }
    
    // 生成中间需要被替换的部分
    const middleReplaceCount = strLength - frontKeep - endKeep;
    const middleReplaceStr = replaceChar.repeat(middleReplaceCount);
    
    // 组合保留的前后部分和中间替换部分
    const maskedStr = originalStr.substring(0, frontKeep) + middleReplaceStr + originalStr.substring(strLength - endKeep);
    
    return maskedStr;
}
/*
 * @Descripttion:
 * @version:
 * @Author: sueRimn
 * @Date: 2019-11-19 10:11:50
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-08-02 16:45:29
 */
const router = {
  path: '/courseLive',
  component: () => import('@view/LiveBroadcastCourse/LiveBroadcastCourse'),
  meta: { requiresAuth: true, menu_id: 10 },
  // meta: { notLogin: true },
  children: [
    {
      path: '',
      component: () => import('@view/LiveBroadcastCourse/List'),
      meta: { requiresAuth: true }
      // meta: { notLogin: true }
    },
    {
      path: 'EditCourse',
      name: 'EditCourse1',
      component: () => import('@view/LiveBroadcastCourse/EditCourse'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '管理' },
        menu_id: 171
      },
      children: [
        {
          path: 'watchBackVideo/:sl_id',
          name: 'watchBackVideo',
          component: () => import('@/components/dataAnalysis/watchbackVideo'),
          meta: {
            requiresAuth: true,
            pageOption: {
              name: '查看回放'
              // prompt:
              //   '数据统计包含系列课；当前课程如存在系列课中，系列课产生的数据也会被统计在当前课程'
            }
          }
          // meta: { notLogin: true }
        },

        {
          path: 'dataAnalysisDetail',
          name: 'dataAnalysisDetail',
          component: () =>
            import('@/components/dataAnalysis/dataAnalysisDetail'),
          meta: {
            requiresAuth: true,
            pageOption: {
              name: '统计详情',
              prompt: '数据统计包含系列课；当前课程如存在系列课中，系列课产生的数据也会被统计在当前课程'
            }
          },
          children: [
            {
              path: 'viewDetail/id/:id',
              component: () => import('@/components/dataAnalysis/viewDetail'),
              meta: {
                requiresAuth: true,
                pageOption: {
                  name: '详情'
                }
              }
              // meta: { notLogin: true }
            }
          ]
          // meta: { notLogin: true }
        }
      ]
      // meta: { notLogin: true }
    },
    {
      path: 'AddCourse',
      component: () => import('@view/LiveBroadcastCourse/AddCourse'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '创建' },
        menu_id: 170
      }
      // meta: { notLogin: true }
    }
  ]
}

export default router

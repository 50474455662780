var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"menuList-contain"},[_c('div',{staticClass:"menuList-logo"},[_c('img',{staticClass:"logo",attrs:{"title":"返回首页","src":_vm.customJgInfo.adminPageLogo ||
            (_vm.customJgInfo.isCustome
              ? ''
              : 'https://img.dingdingkaike.com/teacher.dingdingkaike/ico_logo.png')},on:{"click":_vm.homePage}})]),_c('ul',{staticClass:"menuList",attrs:{"l":""}},_vm._l((_vm.menuList),function(item){return _c('li',{key:item.name,staticClass:"item"},[_c('div',{staticClass:"nav",class:{
            active:
              _vm.childMenuData.length > 0 &&
              _vm.isShowHidden &&
              item.menu_id == _vm.childMenuData[0].pid,
          },on:{"mouseenter":function($event){return _vm.getTopPosition($event, item)},"mouseleave":function($event){return _vm.resetTop('onNav')}}},[_c('i',{class:`icon bg-ico_${item.menu_id}`}),_c('span',[_vm._v(_vm._s(item.name))]),_c('i',{staticClass:"right-arrow"})]),_vm._l((item.menu),function(item2){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(item2.is_select == 1),expression:"item2.is_select == 1"}],key:item2.menu_id,staticClass:"child-name",class:{
            activeStatus: _vm.currRouterPage.menu_id === item2.menu_id,
          },on:{"click":function($event){return _vm.toTargetPage(item2)}}},[_vm._v(" "+_vm._s(item2.name)+" "),(item2.is_new_label == 1)?_c('img',{attrs:{"src":require("../../assets/img/ico_new.png")}}):_vm._e()])})],2)}),0)]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowHidden),expression:"isShowHidden"}],ref:"hiddenMenu",staticClass:"hidden-menu",style:(`top:${_vm.topDis}px;opacity:${_vm.hiddenOpacity}`),on:{"mouseenter":function($event){return _vm.resetTop('onChild')},"mouseleave":function($event){return _vm.resetTop('onNav')}}},_vm._l((_vm.childMenuData),function(item,index){return _c('li',{key:item.menu_id,staticClass:"cp box",class:{ choose: item.select }},[_c('span',{staticClass:"jg-name",on:{"click":function($event){return _vm.toTargetPage(item)}}},[_vm._v(_vm._s(item.name))]),_c('i',{on:{"click":function($event){$event.stopPropagation();return _vm.getFixedOnParentMenu(item, index)}}})])}),0),(_vm.iframeSrc)?_c('my-iframe',{attrs:{"src":_vm.iframeSrc},on:{"close":function($event){_vm.iframeSrc = ''}}}):_vm._e(),(_vm.iphoneView)?_c('cannot-nav',{attrs:{"currentUrl":_vm.currentUrl},on:{"close":_vm.toggleIphoneView}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
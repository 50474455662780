/*
 * @Descripttion:
 * @version:
 * @Author: sueRimn
 * @Date: 2019-11-19 10:11:50
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-12-07 10:50:14
 */

const router = {
  path: '/courseSeries',
  component: () => import('@view/SeriesCourse/SeriesCourse'),
  meta: { requiresAuth: true, menu_id: 13 },
  // meta: { notLogin: true },
  children: [
    {
      path: '',
      component: () => import('@view/SeriesCourse/List'),
      meta: { requiresAuth: true }
      // meta: { notLogin: true }
    },
    {
      path: 'EditCourse',
      component: () => import('@view/SeriesCourse/EditCourse'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '编辑' },
        menu_id: 186
      }
      // meta: { notLogin: true }
    },
    {
      path: 'AddCourse',
      component: () => import('@view/SeriesCourse/AddCourse'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '创建' },
        menu_id: 185
      }
      // meta: { notLogin: true }
    }
  ]
}

export default router

/*
 * @Author: cyy
 * @Date: 2021-11-09 09:43:51
 * @LastEditTime: 2022-09-06 15:57:53
 * @LastEditors: cyy
 * @Description:
 * @FilePath: \teacherdingding.com\src\router\module\assetsAdmin\order.js
 */
/**
 *  @description 老师、管理员订单页面
 */

const router = {
  path: '/capitalOrder',
  name: 'capitalOrder',
  component: () => import('@view/Order/index'),
  meta: { requiresAuth: true, menu_id: 155 },
  children: [
    // 会员 课程
    {
      path: 'detail',
      name: 'detail',
      component: () => import('@view/Order/detail'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '详情' },
      },
    },
    // 公开课 
    {
      path: 'opencourseDetail/:id',
      name: 'opencourseDetail',
      component: () => import('@view/Order/openCourse/opencourseDetail'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '详情' },
      },
    },
    // 代金券订单 商品订单
    {
      path: 'cashcouponDetail/:id',
      name: 'cashcouponDetail',
      component: () => import('@view/Order/cashCoupon/cashcouponDetail'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '详情' },
      },
    },
    // 资料
    {
      path: 'orderData/:id',
      name: 'orderData',
      component: () => import('@view/dataPage/components/detailData.vue'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '详情' },
      },
    },

    // 图文 
    {
      path: 'graDetails',
      name: 'graDetails',
      component: () => import('@view/Order/graphic/graphicDetail'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '详情' },
      },
    },
  ],
}

export default router

/*
 * @Author: cyy
 * @version: 
 * @Date: 2022-06-27 15:38:45
 * @LastEditTime: 2022-09-05 18:43:43
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\router\module\marketingCenter\tuigaung.js
 */
const router = {
  path: '/tuiguang',
  name: 'tuiguang',
  component: () => import('@view/coursePromotion/coursePromotion'),
  // meta:{notLogin: true}
  meta: { requiresAuth: true },
  children: [
    {
      path: 'addCoursePromotion',
      name: 'addCoursePromotion',
      component: () => import('@view/coursePromotion/addCoursePromotion'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '新建' }
      }
    },
    {
      path: 'formTongji',
      name: 'formTongji',
      component: () => import('@view/coursePromotion/formTongji'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '表单统计' }
      }
    },
    {
      path: 'orderTongji',
      name: 'orderTongji',
      component: () => import('@view/coursePromotion/orderTongji'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '订单统计' }
      }
    },
    {
      path: 'enterpriseWechat',
      name: 'enterpriseWechat',
      component: () => import('@view/coursePromotion/enterpriseWechat'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '添加企业微信' }
      }
    },
  ]
}
export default router

/**
 *  @description 网校设置
 */

const router = {
  path: '/setting/school',
  name: 'settingSchool',
  component: () => import('@view/schoolSetting/index'),
  meta: { requiresAuth: true, menu_id: 68 },
  children:[
    {
      path: 'storeCertification',
      name: 'storeCertification',
      component: () => import('@view/schoolSetting/storeCertification'),
      meta: { 
        pageOption: { name: '店铺认证',isReplace:1 }, 
      }
    },
  ]
}
export default router

/*
 * @Descripttion:
 * @version:
 * @Author: sueRimn
 * @Date: 2019-11-05 17:20:47
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-11-11 17:59:39
 */
/**
 *  @description 今日安排页面
 */

const router = {
  path: '/',
  name: 'schoolntro',
  component: () => import('@view/schoolntro/schoolntro'),
  meta: { requiresAuth: true, menu_id: 57 }
}

export default router

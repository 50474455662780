/*
 * @Descripttion:
 * @version: 1.1pc
 * @Author: sueRimn
 * @Date: 2019-10-29 10:21:03
 * @LastEditors  : sueRimn
 * @LastEditTime : 2019-12-20 15:34:03
 * 
 * 
 * 模板
 this.$http({
    // 接口名称
    name: "",
    url: "",
    // 接口所需参数POST (可不填)
    data: {},
    // 成功回调 (可不填)
    callback() {}, 
    // 失败提示语 (可不填)
    errorMessage: "",
    // 失败回调 (可不填)
    error() {},
    // 成功失败都会进行的回调 (可不填)
    complete() {},
    // 请求只执行一次 (可不填)
    once: Boolean,
    // 是否成功一次 就不再请求相同的 (可不填)
    succeedOnce: false, 
    // 请求模式 (可不填)
    *  initialSelf 保持最初的第一个 后续的同样的自己 就进行阻止  (默认)
    *  replacePrev 如果有后一个就停止掉前一个
    *  all 无论多少个 可同时进行
     *
    // isMultipleSelf: "initialSelf"
    // })
 */
import { baseUrl } from '@/config'
export default {
  url: '',
  method: 'post',
  baseURL: baseUrl,
  timeout: 30000,
  responseType: 'json',
  headers: {
    'X-Requested-with': 'XMLHttpRequest',
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

/*
 * @Descripttion:
 * @version:
 * @Author: sueRimn
 * @Date: 2019-11-04 17:29:22
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-11-14 20:41:42
 */
import store from '@/store/store'

// 读取cookies
function getCookie(name) {
  let arr,
    reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')

  if ((arr = document.cookie.match(reg))) return arr[2]
  else return null
}

// 设置cookies
function setCookie(name, value) {
  const Days = 30
  const exp = new Date()
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
  document.cookie = name + `=${value};expires=${exp.toGMTString()};path=/`
}

// 删除cookies
function delCookie(name) {
  const exp = new Date()
  exp.setTime(exp.getTime() - 1)
  const cval = getCookie(name)
  if (cval != null) {
    document.cookie = name + `=${cval};expires=${exp.toGMTString()};path=/`
  }
}

function resetCookie() {
  setCookie('PHPSESSID', '')
  setCookie('userKey', '')
  delCookie('PHPSESSID')
  delCookie('userKey')
}

function resetLocalCache() {
  window.localStorage.clear()
  window.sessionStorage.clear()
}

function logOut() {
  resetCookie()
  resetLocalCache()
  // 如果是定制化机构的话
  let url = location.href
  if (store.state.jid) {
    url += (/\?/.test(url) ? '&' : '?') + `jid=${store.state.jid}`
  }
  location.replace(url)
  location.reload()
}

export { delCookie, getCookie, logOut, setCookie, resetCookie, resetLocalCache }

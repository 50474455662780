<template>
  <fade>
    <div class="fadeConfirmChild errorMessageConfirm">
      <div class="content">
        <span class="icon"></span>
        <span class="text">{{ errorMessage }}</span>
      </div>
      <div class="bottom">
        <button class="btn" @click="exit">刷新</button>
      </div>
    </div>
  </fade>
</template>
<script>
import fade from '@/components/FadeAnimation'

export default {
  name: 'errorMessageConfirm',

  props: {
    errorMessage: String
  },

  components: {
    fade
  },

  methods: {
    exit() {
      // 错误信息弹窗 点击刷新
      window.location.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
.errorMessageConfirm {
  width: 438px;
  height: 150px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(224, 224, 224, 1);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  .content {
    display: flex;
    align-items: center;
    padding: 21px 20px;
    .icon {
      width: 35px;
      height: 35px;
      background: url('~@ass/img/ico_error.png') no-repeat;
    }
    .text {
      font-size: 16px;
      font-weight: bold;
      line-height: 21px;
      margin-left: 20px;
      color: rgba(51, 51, 51, 1);
    }
  }
  .bottom {
    font-size: 0;
    padding: 32px 20px 18px;
    text-align: right;
    .btn {
      border: 0;
      width: 75px;
      height: 24px;
      cursor: pointer;
      display: inline-block;
      border-radius: 2px;
      font-size: 12px;
      line-height: 16px;
      color: rgba(255, 255, 255, 1);
      background: rgba(243, 151, 0, 1);
    }
  }
}
</style>

import qs from 'qs'
import axios from 'axios'
import config from './ajaxConfig'

if (process.env.NODE_ENV !== 'production') {
  axios.defaults.withCredentials = true
}

function Axios(options, config, callback, errorCallback) {
  this.axConfig = config
  this.callback = callback
  this.errorCallback = errorCallback
  this.option = Object.assign({}, defaultOption, options)

  // 如果点击手机上的返回按钮浏览器会缓存住AJAX请求 就不会在去请求
  // 需要带上时间戳 代表每一次都是一个新的请求
  let url = this.option.url
  this.option.url =
    url.indexOf('?') < 0 ? url + '?t=' + +new Date() : url + '&t=' + +new Date()
}

Axios.prototype.get = function() {
  return this.handle(axios.get(this.option.url, this.axConfig), this)
}

Axios.prototype.post = function() {
  return this.handle(
    axios.post(this.option.url, qs.stringify(this.option.data), this.axConfig),
    this
  )
}

Axios.prototype.then = function(result) {
  this.resetAjaxState()
  this.callback && this.callback(result, this.option)
}

Axios.prototype.catch = function(err) {
  // 如果是取消的操作就不清空
  if (typeof err === 'object' && err.__CANCEL__) return
  this.resetAjaxState()
  this.errorCallback && this.errorCallback(err, this.option)
}

Axios.prototype.handle = function(obj, context) {
  obj.then(this.then.bind(context))
  obj.catch(this.catch.bind(context))
  return obj
}

// 如果只能点击一次 就不要删除它的状态
Axios.prototype.resetAjaxState = function() {
  if (!delete axiosProcessCount[this.option.name]) {
    axiosProcessCount[this.option.name] = null
  }
}

function $http(options, callback, errorCallback) {
  options.name = options.name || options.url
  let selfAjaxState = axiosProcessCount[options.name]
  // 多个自己不能同时请求 就直接返回 不进行任何操作
  if (options.isMultipleSelf === 'initialSelf' && selfAjaxState)
    return axiosProcessCount

  // 长江后浪推前浪 假如前一个自己正在进行ajax 后一个进来了 应该停止掉前一个 但是不影响其他的
  if (
    (!options.isMultipleSelf || options.isMultipleSelf === 'replacePrev') &&
    selfAjaxState
  ) {
    selfAjaxState.value.cancelXhr.cancel('cancel xhr')
  }

  // 获取配置信息
  let newConfig = Object.assign({}, config)
  // 添加取消 停止的状态
  options.cancelXhr = cancelToken.source()
  newConfig.cancelToken = options.cancelXhr.token

  // 运行 start
  let axios = new Axios(options, newConfig, callback, errorCallback)

  // 赋予要运行的ajax状态
  axiosProcessCount[options.name] = {
    state: 'initial',
    value: options,
    abort: options.cancelXhr.cancel,
    request: options.data ? axios.post() : axios.get(),
  }

  return axiosProcessCount[options.name]
}

let cancelToken = axios.CancelToken
let defaultOption = {
  url: location.href, // 默认url
  cancelXhr: '', // 上一次xhr对象 可用这个对象停止xhr
  /**
   *  initialSelf 保持最初的第一个 后续的同样的自己 就进行阻止  (默认)
   *  replacePrev 如果有后一个就停止掉前一个
   *  all 无论多少个 可同时进行
   */
  isMultipleSelf: 'replacePrev', // 防止多个同样的ajax同时进行
}

let axiosProcessCount = {}
export default $http

/**
 *  @description 版权保护页面
 */

const router = {
  path: '/videoSafe',
  name: 'videoSafe',
  component: () => import('@view/copyright/index'),
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: 'video',
      name: 'video',
      component: () => import('@/views/copyright/videoprotect'),
      meta: { menu_id: 159 },

      children: [
        {
          path: 'newly1',
          name: 'newly1',
          component: () => import('@view/copyright/newly1'),
          meta: {
            requiresAuth: true,
            pageOption: {
              name: '新建',
              menu_id: 97
            }
          }
        }
      ]
    },
    {
      path: 'brand',
      name: 'brand',
      component: () => import('@/views/copyright/brand'),
      meta: { menu_id: 160 },

      children: [
        {
          path: 'newly2',
          name: 'newly2',
          component: () => import('@view/copyright/newly2'),
          meta: {
            pageOption: { name: '新建' },
            menu_id: 101
          }
        }
      ]
    },
    {
      path: 'account',
      name: 'account',
      component: () => import('@/views/copyright/account'),
      meta: { menu_id: 81 }
    },
    {
      path: 'trace',
      name: 'trace',
      component: () => import('@/views/copyright/safeguarding'),
      meta: { menu_id: 82 }
    },
    {
      path: 'copyright',
      name: 'copyright',
      component: () => import('@/views/copyright/register'),
      meta: { menu_id: 83 }
    }
  ]
}

export default router

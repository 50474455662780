/*
 * @Descripttion:
 * @version:
 * @Author: sueRimn
 * @Date: 2019-11-19 10:11:50
 * @LastEditors: cyy
 * @LastEditTime: 2022-09-23 11:11:52
 */

const router = {
  path: '/courseRecord',
  component: () => import('@view/LiveCallCourse/LiveCallCourse'),
  meta: { requiresAuth: true, menu_id: 11 },
  // meta: { notLogin: true },
  children: [
    {
      path: '',
      component: () => import('@view/LiveCallCourse/List'),
      meta: { requiresAuth: true }
      // meta: { notLogin: true }
    },
    {
      path: 'EditCourse',
      name: 'EditCourse3',
      component: () => import('@view/LiveCallCourse/EditCourse'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '管理' },
        menu_id: 178,
      },
      children: [
        {
          path: 'dataAnalysisDetail',
          name: 'dataAnalysisDetail3',
          component: () =>
            import('@/components/dataAnalysis/dataAnalysisDetail3'),
          meta: {
            requiresAuth: true,
            pageOption: {
              name: '统计详情',
              prompt:
                '数据统计包含系列课；当前课程如存在系列课中，系列课产生的数据也会被统计在当前课程'
            }
          },
          children: [
            {
              path: 'viewDetail/id/:id',
              component: () => import('@/components/dataAnalysis/viewDetail'),
              meta: {
                requiresAuth: true,
                pageOption: {
                  name: '详情'
                }
              }
              // meta: { notLogin: true }
            }
          ]
          // meta: { notLogin: true }
        },



      ]
      // meta: { notLogin: true }
    },
    {
      path: 'AddCourse',
      component: () => import('@view/LiveCallCourse/AddCourse'),
      meta: {
        requiresAuth: true,
        pageOption: { name: '创建' },
        menu_id: 176
      }
      // meta: { notLogin: true }
    }
  ]
}

export default router

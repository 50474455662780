<template>
  <div>
    <div class="menuList-contain">
      <div class="menuList-logo">
        <img
          class="logo"
          @click="homePage"
          title="返回首页"
          :src="
            customJgInfo.adminPageLogo ||
              (customJgInfo.isCustome
                ? ''
                : 'https://img.dingdingkaike.com/teacher.dingdingkaike/ico_logo.png')
          "
        />
      </div>
      <ul l class="menuList">
        <li v-for="item in menuList" :key="item.name" class="item">
          <div
            class="nav"
            :class="{
              active:
                childMenuData.length > 0 &&
                isShowHidden &&
                item.menu_id == childMenuData[0].pid,
            }"
            @mouseenter="getTopPosition($event, item)"
            @mouseleave="resetTop('onNav')"
          >
            <i :class="`icon bg-ico_${item.menu_id}`"></i>
            <span>{{ item.name }}</span>
            <i class="right-arrow"></i>
          </div>
          <span
            class="child-name"
            :class="{
              activeStatus: currRouterPage.menu_id === item2.menu_id,
            }"
            @click="toTargetPage(item2)"
            v-for="item2 in item.menu"
            :key="item2.menu_id"
            v-show="item2.is_select == 1"
          >
            {{ item2.name }}
            <img
              v-if="item2.is_new_label == 1"
              src="../../assets/img/ico_new.png"
            />
          </span>
        </li>
      </ul>
    </div>
    <ul
      class="hidden-menu"
      ref="hiddenMenu"
      v-show="isShowHidden"
      :style="`top:${topDis}px;opacity:${hiddenOpacity}`"
      @mouseenter="resetTop('onChild')"
      @mouseleave="resetTop('onNav')"
    >
      <li
        class="cp box"
        :class="{ choose: item.select }"
        v-for="(item, index) in childMenuData"
        :key="item.menu_id"
      >
        <span class="jg-name" @click="toTargetPage(item)">{{ item.name }}</span>
        <i @click.stop="getFixedOnParentMenu(item, index)"></i>
      </li>
    </ul>
    <!--内嵌网页-->
    <my-iframe
      v-if="iframeSrc"
      :src="iframeSrc"
      @close="iframeSrc = ''"
    ></my-iframe>
    <!--手机版本操作-->
    <cannot-nav
      v-if="iphoneView"
      :currentUrl="currentUrl"
      @close="toggleIphoneView"
    ></cannot-nav>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import cannotNav from '@/components/CannotNav'
import tool from '@ass/js/tool'
export default {
  name: 'menuList',

  components: {
    cannotNav,
  },

  data() {
    return {
      iframeSrc: '',
      createCourseConfirm: false,
      currentUrl: '',
      topDis: 0,
      isShowHidden: false,
      childMenuData: [],
      iphoneView: false,
      hiddenOpacity: 1,
    }
  },

  computed: {
    ...mapState(['currRouterPage', 'userInfo', 'customJgInfo']),
    ...mapGetters(['menuList']),
  },

  methods: {
    toggleIphoneView(url = '') {
      if (typeof url === 'string') {
        this.currentUrl = `${this.userInfo.teacherDomain}/${url}`
      }
      this.iphoneView = !this.iphoneView
    },

    // 获取隐藏二级菜单应该显示的位置的高度
    getTopPosition($event, { menu_id }) {
      if (
        this.childMenuData.length > 0 &&
        this.cacheMenuData &&
        this.cacheMenuData[menu_id]
      ) {
        this.childMenuData = this.cacheMenuData[menu_id]
        this.sureChildPosition($event)
        return
      }
      this.getChildMenu($event, menu_id)
    },

    // 确定隐藏子菜单位置
    sureChildPosition($event) {
      this.isShowHidden = true
      if (!this.$refs.hiddenMenu) return
      this.$nextTick(() => {
        this.topDis = $event.clientY - $event.offsetY
        const temp = this.topDis + this.$refs.hiddenMenu.offsetHeight
        const windowheight = tool.getViewPortHeight()
        if (temp >= windowheight) {
          this.topDis = windowheight - this.$refs.hiddenMenu.offsetHeight - 10
        }
      })
    },

    // 获取二级菜单
    getChildMenu($event, menu_id) {
      this.childMenuData = []
      this.$http({
        name: 'getLastMenu',
        isMultipleSelf: 'replace',
        url: '/menu/getLastMenu',
        data: {
          menu_id,
        },
        callback: ({ data }) => {
          this.childMenuData = data.filter(v =>
            this.$store.state.roots.includes(Number(v.menu_id))
          )
          if (!this.cacheMenuData) {
            this.cacheMenuData = {}
          }
          this.cacheMenuData[this.childMenuData[0].pid] = _.concat(
            [],
            this.childMenuData
          )
          // 避免重复移入
          if (this.isShowHidden) {
            this.sureChildPosition($event)
          }
        },
      })
      this.sureChildPosition($event)
    },

    // 叮钉子
    getFixedOnParentMenu({ menu_id, select }, index) {
      this.$http({
        name: 'setMenu',
        url: '/menu/setMenu',
        data: {
          menu_id,
          select: !select ? 1 : 2,
        },
        callback: () => {
          this.childMenuData[index].select = !select
          this.$store.dispatch('getMenuList')
        },
      })
    },

    // 隐藏子模块位置重置
    resetTop(type = 'onNav') {
      this.isShowHidden = type === 'onChild'
    },

    // 点击跳转目标页
    toTargetPage({ status, is_services, path }) {
      if (status == 2) {
        this.$root.toggleProductUpdate()
        return
      }
      // 是否具有权限
      if (is_services == 2) {
        if (path !== '/h5School') {
          this.$root.toggleUpdatePopup()
          return
        }
      }
      // 等于3 说明需要弹出iframe
      if (status == 3) {
        this.setIframeSrc(path)
      } else if (this.$route.fullPath === path || this.$route.path === path) {
        // 如果点击相同路由重新加载页面
        this.$root.reload()
      } else if (path == '/OpenClass_openClassLive') {
        let url = this.$router.resolve({
          path: path,
        })
        this.$store.dispatch('open', url.href)
      } else {
        this.$router.push({
          path,
        })
      }
    },

    homePage() {
      this.$router.replace({
        path: '/',
      })
    },

    setIframeSrc(src) {
      this.iframeSrc = src
    },

    // 这个用户是否有权限去加载这些模块
    isShowModule(menu_id) {
      return this.$store.state.roots.include(menu_id)
    },
  },
}
</script>
<style lang="scss" scoped>
.menuList-contain {
  width: $menuWidth;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  background: rgba(38, 47, 66, 1);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.menuList-logo {
  padding: 10px 0 37px;
  .logo {
    height: 40px;
    margin: 0 auto;
    cursor: pointer;
  }
}
.menuList {
  .item {
    position: relative;
    cursor: pointer;
    margin-bottom: 12px;
    .nav {
      position: relative;
      display: flex;
      align-items: center;
      color: #fff;
      padding: 8px 0;
      padding-left: 15px;
      font-size: 16px;
      text-align: left;
      .icon {
        display: inline-block;
        margin-right: 12px;
        background-size: 16px 157px;
      }
      .right-arrow {
        position: absolute;
        right: 14px;
        width: 6px;
        height: 10px;
        background: url('~@ass/img/menu/ico_zk.png') no-repeat center/100% 100%;
      }
      &:hover {
        background: #3b465e;
      }
      &.active {
        background: #3b465e;
      }
      &.gray-nav {
        color: #ccc;
        opacity: 0.5;
      }
      &.course-nav {
        color: #fff;
      }
    }
    .child-name {
      display: inline-block;
      width: 50%;
      margin-bottom: 1px;
      padding: 8px 0 8px 11px;
      color: #ccc;
      font-size: 12px;
      box-sizing: border-box;
      border-left: 4px solid rgba(38, 47, 66, 1);
      position: relative;
      img {
        position: absolute;
        top: -8px;
        left: 10px;
        border-radius: 0;
      }
      &:hover {
        background: #976006;
        border-left: 4px solid #f39700;
        border-radius: 0px 100px 100px 0px;
      }
      &:nth-child(2n) {
        &.activeStatus {
          background: #976006;
          border-left: 4px solid #f39700;
          border-radius: 0px 100px 100px 0px;
          color: #fff;
        }
      }
      &:nth-child(2n + 1) {
        &:hover {
          border-left: 4px solid rgba(38, 47, 66, 1);
          border-right: 4px solid #f39700;
          border-radius: 100px 0 0 100px;
        }
        &.activeStatus {
          background: #976006;
          border-left: 4px solid rgba(38, 47, 66, 1);
          border-right: 4px solid #f39700;
          border-radius: 100px 0 0 100px;
          color: #fff;
        }
      }
    }
    .gray-child-name {
      opacity: 0.5;
    }
    .white-child-name {
      color: #fff;
    }
  }
  .item2 {
    &.selected,
    &:hover {
      background: #3b465e;
    }
    &:last-child {
      display: none;
    }
  }
}
.hidden-menu {
  position: fixed;
  left: $menuWidth;
  visibility: visible;
  width: 140px;
  padding: 18px 11px 20px 20px;
  box-sizing: border-box;
  background: #3b465e;
  z-index: 99999;
  .box {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    .jg-name {
      color: #fff;
      font-size: 12px;
      border: none;
      &:hover {
        color: #f39802;
      }
    }
    i {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url('~@ass/img/menu/ico_tdwx@2x.png') no-repeat center/100%
        100%;
    }
  }
  .choose {
    i {
      background: url('~@ass/img/menu/ico_tdyx@2x.png') no-repeat center/100%
        100%;
    }
  }
}
.menuList-create-class {
  border: 0;
  color: #fff;
  width: 116px;
  height: 38px;
  font-size: 14px;
  background: rgba(243, 151, 0, 1);
  border-radius: 2px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  cursor: pointer;
  margin-left: -58px;
  &:hover {
    background: #da8801;
  }
}

.bg-ico_200 {
  width: 16px;
  height: 15px;
  background: url('~@ass/img/menu/icon_zbgl@2x.png') no-repeat;
  background-size: contain !important;
}

.bg-ico_47 {
  width: 16px;
  height: 15px;
  background: url('~@ass/img/menu/menu_icon.png') -0 -80px;
}
.bg-ico_69 {
  width: 16px;
  height: 16px;
  background: url('~@ass/img/menu/menu_icon.png') -0 -141px;
}
.bg-ico_37 {
  width: 16px;
  height: 13px;
  background: url('~@ass/img/menu/menu_icon.png') -0 -25px;
}
.bg-ico_31 {
  width: 14px;
  height: 14px;
  background: url('~@ass/img/menu/menu_icon.png') -0 -38px;
}
.bg-ico_27 {
  width: 14px;
  height: 16px;
  background: url('~@ass/img/menu/menu_icon.png') -0 -125px;
}
.bg-ico_64 {
  width: 16px;
  height: 13px;
  background: url('~@ass/img/menu/menu_icon.png') -0 -12px;
}
.bg-ico_16 {
  width: 16px;
  height: 15px;
  background: url('~@ass/img/menu/menu_icon.png') -0 -95px;
}
.bg-ico_78 {
  width: 16px;
  height: 14px;
  background: url('~@ass/img/menu/menu_icon.png') -0 -66px;
}
.bg-ico_9 {
  width: 16px;
  height: 12px;
  background: url('~@ass/img/menu/menu_icon.png') -0 -0;
}
.bg-ico_1 {
  width: 16px;
  height: 15px;
  background: url('~@ass/img/menu/menu_icon.png') -0 -110px;
}
.bg-ico_56 {
  width: 14px;
  height: 14px;
  background: url('~@ass/img/menu/menu_icon.png') -0 -52px;
}
</style>

/**
 *  @description 老师、管理员订单页面
 */

const router = {
  path: '/orderData/:id',
  name: 'orderData',
  component: () => import('../../../views/dataPage/components/detailData.vue'),
  meta: {
  //   requiresAuth: true,
    pageOption: { name: '详情' }
  }
  
}

export default router

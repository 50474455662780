/*
 * @Descripttion:智能模板
 * @version:1.4.42
 * @Author: pxd
 * @Date: 2023-12-6 09:13
 * @LastEditors: pxd
 * @LastEditTime: 2021-11-01  18:25:55
 */
/**
 *  @description 智能模板
 */


const router = {
    path: '/smartTemplates',
    name: 'smartTemplates',
    component: () => import('@view/LiveBroadcastManagement/smartTemplates/main'),
    meta: { requiresAuth: true, menu_id: 206 },
}

export default router
import getEnv from '@/config/getEnv'

// 公共url
let baseUrl = '' // 项目url

if (getEnv() === 'prod') {
  baseUrl = 'https://teacher.dingdingkaike.com'
} else if (getEnv() === 'release') {
  baseUrl = 'https://teacher-release.dingdingkaike.com'
} else if (getEnv() === 'test') {
  baseUrl = 'https://teacher-test.dingdingkaike.com'
} else {
  baseUrl = 'https://teacher-test.dingdingkaike.com'
}

export { baseUrl }

/*
 * @Descripttion: teacher.dingdingkaike
 * @version: 1.1pcMenu
 * @Author: sueRimn
 * @Date: 2019-11-07 18:12:51
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-11-11 18:23:47
 */
/**
 *  @description 账号设置页面
 */

const router = {
  path: '/accountSettings',
  name: 'accountSettings',
  component: () => import('@view/AccountSettings/AccountSettings'),

  // meta: { requiresAuth: false,notLogin :true}
  meta: { requiresAuth: true }
}

export default router

/*
 * @Descripttion:通用设置
 * @version: 1.4.2.
 * @Author: cyy
 * @Date: 2021-04-19 17:29:22
 * @LastEditors: cyy
 * @LastEditTime: 2022-01-24 11:08:28
 */
/**
 *  @description 通用设置
 */


const router = {
    path: '/liveSettings',
    name: 'liveSettings',
    component: () => import('@view/LiveBroadcastManagement/currencySetting/main'),
    meta: { requiresAuth: true, menu_id: 203 },
}

// prompt: '在当前课程'
export default router
<!---广告--->
<template>
  <transition name="fade">
    <div
      v-if="advertising.src && showAdvertising"
      class="mask"
      @click.self="showAdvertising = false"
    >
      <div class="absoluteCenter advertising">
        <img @click="nav" class="img" :src="advertising.src" alt="" />
        <div class="close" @click="showAdvertising = false"></div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'Advertising',

  data() {
    return {
      showAdvertising: true,
    }
  },

  computed: {
    advertising() {
      return this.$store.state.advertising
    },
  },

  watch: {
    advertising: {
      handler(data) {
        if (data.src) {
          const name = 'adverFlag'
          const value = localStorage.getItem(name)
          // 从缓存中取出标识
          // 1.如果现在存储的图url 不等于 新的图url 就说明要显示
          if (value && value == this.advertising.src) {
            this.showAdvertising = false
          } else {
            localStorage.setItem(name, this.advertising.src)
            this.showAdvertising = true
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    // 点击图片跳转
    nav() {
      if (this.advertising.url) {
        // 如果是外部链接
        if (/^http/.test(this.advertising.url)) {
          this.$store.dispatch('open', this.advertising.url)
        } else {
          this.$router.push(this.advertising.url)
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.advertising {
  .img {
    max-width: 568px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
  }
  .close {
    width: 26px;
    height: 26px;
    cursor: pointer;
    position: absolute;
    background: url('~@ass/img/1.3.2/ico_gbtc.png');
    background-size: 100%;
    right: -26px;
    top: -26px;
  }
}
</style>

/**
 *  @description 学生页面
 */

const router = {
  path: '/manageStudent',
  name: 'manageStudent',
  component: () => import('@view/teachingAdmin/student'),
  meta: { requiresAuth: true, menu_id: 152 },
  children: [
    {
      path: 'watchrecords',
      name: 'watchrecords',
      component: () => import('@view/teachingAdmin/teachingAdmin/watchrecords'),
      meta: {
        pageOption: {
          name: '观看记录',
          prompt:
            '重要提示：观看记录的数据已不再更新，网校详细扣费记录已上线，请在【网校账户】中查看',
          iconPrompt: 2,
          color: '#FF3535'
        }
      }
    },
    {
      path: 'detail',
      name: 'detail',
      component: () => import('@view/teachingAdmin/watchrecords'),
      meta: { pageOption: { name: '详情' } }
    },
    {
      path: 'statisticsdata',
      name: 'statisticsdata',
      component: () => import('@view/teachingAdmin/teachingAdmin/statisticsdata'),
      meta: { pageOption: { name: '数据统计' } }
    }
  ]
}

export default router

/**
 *  @description 网校装修
 */

const router = {
    path: '/onlineSchoolDecoration',
    name: 'onlineSchoolDecoration',
    component: () => import('@view/NetSchollAdmin/onlineSchoolDecoration/onlineSchoolDecoration'),
    meta: { requiresAuth: true, topPage: true }
}

export default router

const state = {
  //课程安排所选择的数据
  teacher_id: '',
  selected: {},
  teacherList: [],
  
}
const getters = {}
const mutations = {
  //课程安排
  set_teacher_id(state, val) {
    state.teacher_id = val
  },
  setSelected(state, val) {
    state.selected = val
  },
  setTeacherList(state, val) {
    state.teacherList = val
  },
  
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

/**
 * 背景色变化
 */
export default {
  mounted() {
    document.body.style.background = '#f5f5f5'
  },

  destroyed() {
    document.body.style.background = '#fff'
  }
}

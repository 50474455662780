/**
 *  @description 统计分析页面
 */

const router = {
  path: '/statisticalAnalysis',
  name: 'statisticalAnalysis',
  component: () => import('@view/statisticalAnalysis/index'),
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: 'overview',
      name: 'overviewAnalysis',
      component: () => import('@/views/statisticalAnalysis/overview'),
      meta: { menu_id: 70 },
    },
    {
      path: 'student',
      name: 'studentAnalysis',
      component: () => import('@/views/statisticalAnalysis/student'),
      meta: { menu_id: 73 },
    },
    {
      path: 'order',
      name: 'orderAnalysis',
      component: () => import('@/views/statisticalAnalysis/orderAnalysis'),
      meta: {
        pageOption: {
          prompt: '统计数据不含当天，每天凌晨更新数据'
        },
        menu_id: 71
      }
    },
    {
      path: 'course',
      name: 'courseAnalysis',
      component: () => import('@/views/statisticalAnalysis/course'),
      meta: { menu_id: 74 },
    },
    {
      path: 'classIn',
      name: 'classIn',
      component: () => import('@/views/statisticalAnalysis/classIn'),
      meta: {
        pageOption: {
          prompt: '统计数据不含当天，每天凌晨更新数据'
        },
        menu_id: 75
      }
    },
    {
      path: 'marketing',
      name: 'marketingAnalysis',
      component: () => import('@/views/statisticalAnalysis/marketing'),
      meta: { menu_id: 76 },
    },
    {
      path: 'flow',
      name: 'flowAnalysis',
      component: () => import('@/views/statisticalAnalysis/flow'),
      meta: { menu_id: 72 },
    },
    // 访问记录
    {
      path: 'records',
      name: 'records',
      component: () => import('@/views/statisticalAnalysis/records'),
      // meta: { menu_id: 72 },
    },
  ]
}

export default router

/**
 *  @description 关联公众号
 */

 const router = {
  path: '/setting/wechat',
  name: 'settingWechat',
  component: () => import('@view/schoolSetting/index'),
  meta: { requiresAuth: true, menu_id: 49 }
}
export default router
